import React, { createContext, useContext, useState, useEffect } from "react";

export const MemberAuthContext = createContext(null);

export function useMemberAuth() {
  return useContext(MemberAuthContext);
}

export const MemberAuthProvider = ({ children }) => {
  const [memberAuthUser, setMemberAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Checking local storage for member auth info");
    const memberAccessToken = localStorage.getItem("memberAccessToken");
    const memberClient = localStorage.getItem("memberClient");
    const memberUid = localStorage.getItem("memberUid");
    console.log("Found in local storage:", { memberAccessToken, memberClient, memberUid });

    if (memberAccessToken && memberClient && memberUid) {
      setMemberAuthUser({ memberAccessToken, memberClient, memberUid });
    }
    setLoading(false);
  }, []);

  const login = ({ memberAccessToken, memberClient, memberUid }) => {
    console.log("Logging in member with:", { memberAccessToken, memberClient, memberUid });
    localStorage.setItem("memberAccessToken", memberAccessToken);
    localStorage.setItem("memberClient", memberClient);
    localStorage.setItem("memberUid", memberUid);
  
    const newMemberUser = { memberAccessToken, memberClient, memberUid };
    setMemberAuthUser(newMemberUser);
  };

  const logout = () => {
    console.log("Logging out member user:", memberAuthUser);
    localStorage.removeItem("memberAccessToken");
    localStorage.removeItem("memberClient");
    localStorage.removeItem("memberUid");
    setMemberAuthUser(null);
    console.log("Member user after logout:", memberAuthUser);
  };

  const value = {
    memberAuthUser,
    loading,
    login,
    logout,
  };

  return <MemberAuthContext.Provider value={value}>{children}</MemberAuthContext.Provider>;
};
