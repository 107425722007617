import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useStaffAuth } from '../../contexts/StaffAuthContext'; // StaffAuthContextからuseStaffAuthをインポート
import './Gym.css';

const GymFooter = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { logout } = useStaffAuth();

  return (
    <Navbar fixed="bottom" bg="light" variant="light" className="gym-footer">
      <Container>
        <Nav className="me-auto">
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/top`)} className="gym-footer-nav-link">TOP</Nav.Link>
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/users`)} className="gym-footer-nav-link">会員様管理</Nav.Link>
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/staffs`)} className="gym-footer-nav-link">スタッフ管理</Nav.Link>
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/mitt_work_summary`)} className="gym-footer-nav-link">稼働状況</Nav.Link>
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/attendances`)} className="gym-footer-nav-link">入退館リスト</Nav.Link>
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/attendance_ranking`)} className="gym-footer-nav-link">ご利用ランキング</Nav.Link>
          <Nav.Link onClick={() => navigate(`/gyms/${slug}/information`)} className="gym-footer-nav-link">ジム情報</Nav.Link>
          <Nav.Link onClick={logout} className="gym-footer-nav-link">ログアウト</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default GymFooter;
