import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useGymStaffService } from "../../services/gymStaffService";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import "./Gym.css";

function GymStaffAdd() {
  const { slug } = useParams();
  const { addGymStaff } = useGymStaffService(slug);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  // const [nameEn, setNameEn] = useState("");
  const [nameKana, setNameKana] = useState(null);
  const [birth, setBirth] = useState("");
  const [gender, setGender] = useState("");
  const [career, setCareer] = useState("");
  const [hobby, setHobby] = useState("");
  // const [isWorking, setIsWorking] = useState(false);
  // const [isRetired, setIsRetired] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const staff = {
      name,
      // name_en: nameEn,
      name_kana: nameKana,
      birth,
      gender,
      career,
      hobby,
      is_working: false,
      is_retired: false,
      email,
      phone,
      password,
      password_confirmation: password,
    };
    const staffData = { staff, slug };
    await addGymStaff(staffData);
    navigate(`/gyms/${slug}/staffs`);
  };

  const handleCancel = () => {
    navigate(`/gyms/${slug}/staffs`);
  };

  return (
    <div className="gym-top-page">
      <GymHeader />
      <Container className="edit-user-container">
        <h2 className="my-4 gym-header sticky-header">スタッフ追加</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="form-group-spacing">
            <Form.Label>氏名</Form.Label>
            <Form.Control
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formNameKana" className="form-group-spacing">
            <Form.Label>氏名カナ</Form.Label>
            <Form.Control
              type="text"
              required
              value={nameKana}
              onChange={(e) => setNameKana(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="form-group-spacing">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formPhone" className="form-group-spacing">
            <Form.Label>電話</Form.Label>
            <Form.Control
              type="text"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBirth" className="form-group-spacing">
            <Form.Label>生年月日</Form.Label>
            <Form.Control
              type="date"
              required
              value={birth}
              onChange={(e) => setBirth(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formGender" className="form-group-spacing">
            <Form.Label>性別</Form.Label>
            <Form.Select
              aria-label="Gender select"
              required
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select...</option>
              <option value="male">男</option>
              <option value="female">女</option>
              <option value="other">未回答</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="formCareer" className="form-group-spacing">
            <Form.Label>経歴</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={career}
              onChange={(e) => setCareer(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formHobby" className="form-group-spacing">
            <Form.Label>趣味</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={hobby}
              onChange={(e) => setHobby(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>パスワード</Form.Label>
            <Form.Control
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autocomplete="new-password"
            />
          </Form.Group>

          <div className="button-group">
            <Button variant="primary" type="submit" className="me-2">
              追加
            </Button>
            <Button variant="outline-secondary" onClick={handleCancel}>
              戻る
            </Button>
          </div>
        </Form>
      </Container>
      <GymFooter />
    </div>
  );
}

export default GymStaffAdd;
