import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import { API_URL } from "../../config";
import useAuthRequest from "../../hooks/useAuthRequest";
import AdminFooter from "./AdminFooter";
import "./Admin.css";

const AddGym = () => {
  const [newGym, setNewGym] = useState({
    name: "",
    name_kana: "",
    phone: "",
    email: "",
    postal_code: "",
    address: "",
    representative_name: "",
    status: 0,
    slug: "",
  });
  const navigate = useNavigate();
  const authRequest = useAuthRequest();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authRequest(`${API_URL}/api/v1/admin/gyms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ gym: newGym }),
      });
      navigate("/admin/gyms");
    } catch (error) {
      console.error("ジムの追加に失敗しました:", error);
    }
  };

  const handleChange = (e) => {
    setNewGym({ ...newGym, [e.target.name]: e.target.value });
  };

  const handleCancel = () => {
    navigate("/admin/gyms");
  };

  const handlePostalCodeChange = async (e) => {
    const postalCode = e.target.value;
    setNewGym({ ...newGym, postal_code: postalCode });

    if (postalCode.length === 7) {
      // 日本の郵便番号の場合
      try {
        const response = await fetch(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
        );
        const data = await response.json();

        if (data.results) {
          const addressInfo = data.results[0];
          const address = `${addressInfo.address1}${addressInfo.address2}${addressInfo.address3}`;
          // ここで postal_code も含めて更新
          setNewGym({ ...newGym, postal_code: postalCode, address: address });
        }
      } catch (error) {
        console.error("住所情報の取得に失敗しました:", error);
      }
    }
  };

  return (
    <div className="admin-container">
      <Card className="admin-card">
        <Card.Body>
          <h2 className="admin-header">ジム追加</h2>
          <Form onSubmit={handleSubmit}>
            {/* Name field */}
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={newGym.name}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Name Kana field */}
            <Form.Group className="mb-3">
              <Form.Label>名前（カナ）</Form.Label>
              <Form.Control
                type="text"
                name="name_kana"
                required
                value={newGym.name_kana}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>slug</Form.Label>
              <Form.Control
                type="text"
                name="slug"
                required
                value={newGym.slug}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Phone field */}
            <Form.Group className="mb-3">
              <Form.Label>電話番号</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                required
                value={newGym.phone}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Email field */}
            <Form.Group className="mb-3">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newGym.email}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Postal Code field */}
            <Form.Group className="mb-3">
              <Form.Label>郵便番号</Form.Label>
              <Form.Control
                type="text"
                name="postal_code"
                value={newGym.postal_code}
                onChange={handlePostalCodeChange}
              />
            </Form.Group>

            {/* Address field */}
            <Form.Group className="mb-3">
              <Form.Label>住所</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={newGym.address}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Representative Name field */}
            <Form.Group className="mb-3">
              <Form.Label>代表者名</Form.Label>
              <Form.Control
                type="text"
                name="representative_name"
                value={newGym.representative_name}
                onChange={handleChange}
              />
            </Form.Group>

            {/* Submit and Cancel buttons */}
            <Button variant="primary" type="submit">
              追加
            </Button>
            <Button variant="secondary" onClick={handleCancel} className="ms-2">
              キャンセル
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <AdminFooter />
    </div>
  );
};

export default AddGym;
