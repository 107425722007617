import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import useStaffAuthRequest from '../../hooks/useStaffAuthRequest';
import { API_URL } from '../../config';
import './Gym.css';
import GymFooter from './GymFooter';

function GymInformationEdit() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const staffAuthRequest = useStaffAuthRequest();
  const [gymInfo, setGymInfo] = useState({
    name: '',
    name_kana: '',
    phone: '',
    email: '',
    postal_code: '',
    address: '',
    representative_name: '',
    homepage_url: '',
  });
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [gymName, setGymName] = useState("");

  useEffect(() => {
    const fetchGymInfo = async () => {
      try {
        const response = await staffAuthRequest(`${API_URL}/api/v1/gym/gym?slug=${slug}`);
        setGymInfo(response);  // 修正された箇所
        setGymName(response.name)
      } catch (error) {
        console.error('Error fetching gym info:', error);
        setAlert({ show: true, message: 'ジム情報の取得に失敗しました。', variant: 'danger' });
      }
    };
    fetchGymInfo();
  }, [slug, staffAuthRequest]);

  const handleChange = (e) => {
    setGymInfo({ ...gymInfo, [e.target.name]: e.target.value });
  };

  const handlePostalCodeChange = async (e) => {
    const postalCode = e.target.value;
    setGymInfo({ ...gymInfo, postal_code: postalCode });
  
    if (postalCode.length === 7) {
      try {
        const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`);
        const data = await response.json();
        if (data.results) {
          const addressInfo = data.results[0];
          const address = `${addressInfo.address1}${addressInfo.address2}${addressInfo.address3}`;
          setGymInfo({ ...gymInfo, postal_code: postalCode, address: address }); // 修正: postal_code も更新
        }
      } catch (error) {
        console.error('住所情報の取得に失敗しました:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await staffAuthRequest(`${API_URL}/api/v1/gym/gym?slug=${slug}`, {
        method: "PUT",
        body: JSON.stringify(gymInfo),
      });
      setGymName(response.name);
      setAlert({ show: true, message: 'ジム情報が更新されました。', variant: 'success' });
    } catch (error) {
      console.error('Error updating gym info:', error);
      setAlert({ show: true, message: 'ジム情報の更新に失敗しました。', variant: 'danger' });
    }
  };

  const handleCancel = () => {
    navigate(`/gyms/${slug}`);
  };

  return (
    <div className="gym-top-page">
      <div className="gym-name-header">
      <h2>{gymName}</h2>
    </div>
      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Container className="edit-user-container">
        <h2 className="my-4 gym-header sticky-header">ジム情報編集</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="form-group-spacing">
            <Form.Label>ジム名</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={gymInfo.name}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formNameKana" className="form-group-spacing">
            <Form.Label>ジム名（カナ）</Form.Label>
            <Form.Control
              type="text"
              name="name_kana"
              value={gymInfo.name_kana}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formPhone" className="form-group-spacing">
            <Form.Label>電話番号</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={gymInfo.phone}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formEmail" className="form-group-spacing">
            <Form.Label>メールアドレス</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={gymInfo.email}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formPostalCode" className="form-group-spacing">
            <Form.Label>郵便番号</Form.Label>
            <Form.Control
              type="text"
              name="postal_code"
              value={gymInfo.postal_code}
              onChange={handlePostalCodeChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formAddress" className="form-group-spacing">
            <Form.Label>住所</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={gymInfo.address}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formRepresentativeName" className="form-group-spacing">
            <Form.Label>代表者名</Form.Label>
            <Form.Control
              type="text"
              name="representative_name"
              value={gymInfo.representative_name}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>

          <Form.Group controlId="formHomepageUrl" className="form-group-spacing">
            <Form.Label>ホームページURL</Form.Label>
            <Form.Control
              type="url"
              name="homepage_url"
              value={gymInfo.homepage_url}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formTwitterUrl" className="form-group-spacing">
            <Form.Label>X（Ex. Twitter）URL</Form.Label>
            <Form.Control
              type="url"
              name="twitter_url"
              value={gymInfo.twitter_url}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <Form.Group controlId="formInstagramUrl" className="form-group-spacing">
            <Form.Label>Instagram</Form.Label>
            <Form.Control
              type="url"
              name="instagram_url"
              value={gymInfo.instagram_url}
              onChange={handleChange}
              className="gym-form-control"
            />
          </Form.Group>
  
          <div className="button-group">
            <Button variant="primary" type="submit" className="me-2">
              情報更新
            </Button>
            <Button variant="outline-secondary" onClick={handleCancel}>
              戻る
            </Button>
          </div>
        </Form>
      </Container>
      <GymFooter />
    </div>
  );
  
}

export default GymInformationEdit;
