import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import AdminFooter from "./AdminFooter";
import "./Admin.css";

const AdminDashboard = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/admin/login");
  };

  return (
    <div className="admin-container">
      <Card className="admin-card">
        <Card.Body>
          <h2 className="admin-header text-center">管理ダッシュボード</h2>
          <ul className="admin-links">
            <li>
              <Link to="/admin/admin_users">管理ユーザー管理</Link>
            </li>
            <li>
              <Link to="/admin/gyms">ジム管理</Link>
            </li>
            {/* 他の管理機能へのリンクを追加 */}
          </ul>
          <button className="admin-button" onClick={handleLogout}>
            ログアウト
          </button>
        </Card.Body>
      </Card>
      <AdminFooter />
    </div>
  );
};

export default AdminDashboard;
