import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth } from '../../contexts/AuthContext'; // AuthContextからuseAuthをインポート

const AdminFooter = () => {
  const { logout } = useAuth(); // ログアウト関数を取得

  return (
    <Navbar fixed="bottom" bg="light" variant="light">
      <Container>
        <Nav className="me-auto">
          <LinkContainer to="/admin/dashboard">
            <Nav.Link>ダッシュボード</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/admin_users">
            <Nav.Link>管理者管理</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/gyms">
            <Nav.Link>ジム管理</Nav.Link>
          </LinkContainer>
          <Nav.Link onClick={logout}>ログアウト</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AdminFooter;
