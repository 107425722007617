import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import useAuthRequest from '../../hooks/useAuthRequest';
import './Admin.css';
import { API_URL } from '../../config';
import AdminFooter from './AdminFooter';

const EditAdminUser = () => {
  const [user, setUser] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const authRequest = useAuthRequest();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await authRequest(`${API_URL}/api/v1/admin/admin_users/${id}`);
        setUser({ name: data.name, email: data.email });
        setLoading(false);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUser();
  }, [id, authRequest]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authRequest(`${API_URL}/api/v1/admin/admin_users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user),
      });
      navigate('/admin/admin_users');
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordChangeSubmit = async () => {
    try {
      await authRequest(`${API_URL}/api/v1/admin/admin_users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          admin_user: {
            password: newPassword,
            password_confirmation: newPassword
          }
        }),
      });
      setShowModal(false);
      alert("パスワードを変更しました");
    } catch (error) {
      setError(error.message);
      alert("パスワード変更に失敗しました");
    }
  };

  const handleCancel = () => {
    navigate('/admin/admin_users');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="admin-container">
      <Card className="admin-card">
        <Card.Body>
          <h2 className="admin-header">管理ユーザー編集</h2>
          <Form onSubmit={handleSubmit}>
            {/* 名前 */}
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                type="text"
                required
                value={user.name}
                onChange={(e) => setUser({...user, name: e.target.value})}
              />
            </Form.Group>
            {/* メールアドレス */}
            <Form.Group className="mb-3">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                required
                value={user.email}
                onChange={(e) => setUser({...user, email: e.target.value})}
              />
            </Form.Group>
            <div className="mb-3">
              <Button variant="info" onClick={() => setShowModal(true)}>パスワード変更</Button>
            </div>
            <Button variant="primary" type="submit">更新</Button>
            <Button variant="secondary" onClick={handleCancel} className="ms-2">キャンセル</Button>
          </Form>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>パスワード変更</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>新しいパスワード</Form.Label>
            <Form.Control
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={handlePasswordChangeSubmit}>
            更新
          </Button>
        </Modal.Footer>
      </Modal>

      <AdminFooter />
    </div>
  );
};

export default EditAdminUser;
