import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Table, Button } from "react-bootstrap";
import { API_URL } from "../../config";
import useAuthRequest from "../../hooks/useAuthRequest";
import AdminFooter from "./AdminFooter";
import "./Admin.css";

const AdminUsersList = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const navigate = useNavigate();
  const authRequest = useAuthRequest();

  const handleRowClick = (userId) => {
    navigate(`/admin/admin_users/edit/${userId}`);
  };
  
  const handleAddUser = () => {
    navigate('/admin/admin_users/add');
  };

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        const data = await authRequest(`${API_URL}/api/v1/admin/admin_users`);
        setAdminUsers(data);
      } catch (error) {
        console.error("管理ユーザーの取得に失敗しました:", error);
      }
    };

    fetchAdminUsers();
  }, [authRequest]);

  return (
    <div className="admin-container">
      <div className="admin-card">
        <h2 className="admin-header">管理ユーザー一覧</h2>
        <Button variant="primary" onClick={handleAddUser} className="mb-3">管理ユーザー追加</Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>名前</th>
              <th>メールアドレス</th>
              <th>作成日</th>
              <th>更新日</th>
            </tr>
          </thead>
          <tbody>
            {adminUsers.map((user) => (
              <tr key={user.id} onClick={() => handleRowClick(user.id)}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{new Date(user.created_at).toLocaleDateString()}</td>
                <td>{new Date(user.updated_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <AdminFooter />
    </div>
  );
};

export default AdminUsersList;
