import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AttendanceList from "./AttendanceList";
import Staffs from "./Staffs";
import MittPracticeStatus from "./Staffs/MittPracticeStatus";
import AddStaff from "./Staffs/AddStaff";
import EditStaff from "./Staffs/EditStaff";
import UserList from "./Users/UserList";
import AddUser from "./Users/AddUser";
import EditUser from "./Users/EditUser";
import EntryExitList from "./EntryExitList";
import Calendar from "./Calendar";
import PracticeMenuPage from "./PracticeMenuPage";
import AttendanceRanking from "./AttendanceRanking";
// contexts
import { AuthProvider } from "./contexts/AuthContext";
import { StaffAuthProvider } from "./contexts/StaffAuthContext";
import { MemberAuthProvider } from "./contexts/MemberAuthContext";
// components/public
import TopPage from "./components/public/TopPage";
import GymInformation from "./components/public/GymInformation";
// components/auth
import PrivateRoute from "./components/auth/PrivateRoute";
import GymStaffRoute from "./components/auth/GymStaffRoute";
import GymMemberRoute from "./components/auth/GymMemberRoute";
// components/admin
import AdminDashboard from "./components/admin/Dashboard";
import AdminLogin from "./components/admin/Login";
import AdminUsersList from "./components/admin/AdminUsersList";
import EditAdminUser from "./components/admin/EditAdminUser";
import AddAdminUser from "./components/admin/AddAdminUser";
import GymsList from "./components/admin/GymsList";
import EditGym from "./components/admin/EditGym";
import AddGym from "./components/admin/AddGym";
// components/gym
import GymStaffLogin from "./components/gym/GymStaffLogin";
import GymTopPage from "./components/gym/TopPage";
import GymStaffList from "./components/gym/StaffList";
import GymStaffEdit from "./components/gym/StaffEdit";
import GymStaffAdd from "./components/gym/StaffAdd";
import GymUserList from "./components/gym/UserList";
import GymUserEdit from "./components/gym/UserEdit";
import GymUserAdd from "./components/gym/UserAdd";
import GymAttendances from "./components/gym/GymAttendances";
import GymInformationEdit from "./components/gym/GymInformationEdit";
import GymMittWorkSummary from "./components/gym/MittWorkSummary";
import GymAttendanceRanking from './components/gym/AttendanceRanking';
// components/members
import MemberTop from "./components/members/MemberTop";
import GymMemberLogin from "./components/members/GymMemberLogin";
import MemberPracticeMenuPage from "./components/members/PracticeMenuPage";
import MemberInformationEdit from "./components/members/MemberInformationEdit";
// components/errors
import ErrorPage from "./components/errors/ErrorPage";

// import ChangeAdminPassword from "./components/admin/ChangeAdminPassword";

function App() {
  return (
    <AuthProvider>
      <StaffAuthProvider>
        <MemberAuthProvider>
          <Router>
            <div className="App">
              <Routes>
                <Route path="/error" element={<ErrorPage />} />
                <Route
                  path="/ranking/checkins"
                  element={<AttendanceRanking />}
                />
                <Route
                  path="/members/practice-menu/:attendanceId"
                  element={<PracticeMenuPage />}
                />
                <Route path="/members/dashboard" element={<Calendar />} />
                <Route path="/users/new" element={<AddUser />} />
                <Route path="/users/edit/:id" element={<EditUser />} />
                <Route path="/users" element={<UserList />} />
                <Route path="/staffs/new" element={<AddStaff />} />
                <Route path="/staffs/edit/:id" element={<EditStaff />} />
                <Route
                  path="/staffs/mitt_practice_status"
                  element={<MittPracticeStatus />}
                />{" "}
                <Route path="/staffs" element={<Staffs />} />
                <Route path="/entry_exit_list" element={<EntryExitList />} />
                <Route
                  path="/attendance-list/:date"
                  element={<AttendanceList />}
                />
                {/* ------ public start ------ */}
                <Route path="/" element={<TopPage />} />
                {/* ------ public end ------ */}
                {/* ------ /admin start ------ */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="/admin/dashboard"
                  element={
                    <PrivateRoute>
                      <AdminDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/admin_users"
                  element={
                    <PrivateRoute>
                      <AdminUsersList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/admin_users/edit/:id"
                  element={
                    <PrivateRoute>
                      <EditAdminUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/admin_users/add"
                  element={
                    <PrivateRoute>
                      <AddAdminUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/gyms"
                  element={
                    <PrivateRoute>
                      <GymsList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/gyms/add"
                  element={
                    <PrivateRoute>
                      <AddGym />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/gyms/edit/:id"
                  element={
                    <PrivateRoute>
                      <EditGym />
                    </PrivateRoute>
                  }
                />
                {/* ------ /admin end ------ */}
                {/* ------ /gyms/:slug start ------ */}
                <Route path="/gyms/:slug" element={<GymInformation />} />
                <Route path="/gyms/:slug/login" element={<GymStaffLogin />} />
                <Route
                  path="/gyms/:slug/top"
                  element={
                    <GymStaffRoute>
                      <GymTopPage />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/staffs"
                  element={
                    <GymStaffRoute>
                      <GymStaffList />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/staffs/edit/:id"
                  element={
                    <GymStaffRoute>
                      <GymStaffEdit />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/staffs/new"
                  element={
                    <GymStaffRoute>
                      <GymStaffAdd />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/users"
                  element={
                    <GymStaffRoute>
                      <GymUserList />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/users/edit/:id"
                  element={
                    <GymStaffRoute>
                      <GymUserEdit />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/users/new"
                  element={
                    <GymStaffRoute>
                      <GymUserAdd />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/attendances"
                  element={
                    <GymStaffRoute>
                      <GymAttendances />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/attendances/:date"
                  element={
                    <GymStaffRoute>
                      <GymTopPage />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/information"
                  element={
                    <GymStaffRoute>
                      <GymInformationEdit />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/mitt_work_summary"
                  element={
                    <GymStaffRoute>
                      <GymMittWorkSummary />
                    </GymStaffRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/attendance_ranking"
                  element={
                    <GymStaffRoute>
                      <GymAttendanceRanking />
                    </GymStaffRoute>
                  }
                />
                {/* ------ /gyms/:slug end ------ */}
                {/* ------ /gyms/:slug/members start ------ */}
                <Route
                  path="/gyms/:slug/members/login"
                  element={<GymMemberLogin />}
                />
                <Route
                  path="/gyms/:slug/members/dashboard"
                  element={
                    <GymMemberRoute>
                      <MemberTop />
                    </GymMemberRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/members/practice_menu/:attendanceId"
                  element={
                    <GymMemberRoute>
                      <MemberPracticeMenuPage />
                    </GymMemberRoute>
                  }
                />
                <Route
                  path="/gyms/:slug/members/information"
                  element={
                    <GymMemberRoute>
                      <MemberInformationEdit />
                    </GymMemberRoute>
                  }
                />
                {/* ------ /gyms/:slug/members end ------ */}
              </Routes>
            </div>
          </Router>
        </MemberAuthProvider>
      </StaffAuthProvider>
    </AuthProvider>
  );
}

export default App;
