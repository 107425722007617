import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { addUser } from '../services/userService';

function AddUser() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    tel: "",
    stance: "unknown", // デフォルト値を設定
    birthday: "",
    issues: "",
    boxing_experience: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addUser(userData);
    navigate('/users');
  }

  const handleCancel = () => {
    navigate('/users');
  };

  return (
    <Container>
      <h2 className="my-4">Add New User</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={userData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formTel">
          <Form.Label>Tel</Form.Label>
          <Form.Control
            type="tel"
            name="tel"
            value={userData.tel}
            onChange={handleChange}
          />
        </Form.Group>
    
        <Form.Group controlId="formHandedness">
          <Form.Label>構え</Form.Label>
          <Form.Control
            as="select"
            name="stance"
            value={userData.stance}
            onChange={handleChange}
          >
            <option value="right">オーソドックス（右）</option>
            <option value="left">サウスポー（左）</option>
            <option value="both">両方</option>
            <option value="unknown">不明</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formBirthday">
          <Form.Label>Birthday</Form.Label>
          <Form.Control
            type="date"
            name="birthday"
            value={userData.birthday}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formIssues">
          <Form.Label>Issues/Concerns</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="issues"
            value={userData.issues}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formBoxingExperience">
          <Form.Label>Boxing Experience (Years)</Form.Label>
          <Form.Control
            type="number"
            name="boxing_experience"
            value={userData.boxing_experience}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add
        </Button>

        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Form>
    </Container>
  );
}

export default AddUser;
