import { useCallback } from 'react';
import useStaffAuthRequest from "../hooks/useStaffAuthRequest";
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';

const GYM_USER_URL = `${API_URL}/api/v1/gym/users`;

export const useGymUserService = (slug) => { // slug を引数として受け取る
  const navigate = useNavigate();

  const staffAuthRequest = useStaffAuthRequest();

  const handleError = useCallback((error) => {
    navigate('/error', { state: { status: error.status || 'Error' } });
  }, [navigate]);

  const getGymUsers = useCallback(async () => {
    try {
      const response = await staffAuthRequest(`${GYM_USER_URL}?slug=${slug}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }, [handleError, slug, staffAuthRequest]);

  const getGymUser = useCallback(async (id) => {
    const response = await staffAuthRequest(`${GYM_USER_URL}/${id}?slug=${slug}`);
    return response;
  }, [slug, staffAuthRequest]);

  const addGymUser = useCallback(async (user) => {
    const userWithSlug = { ...user, slug };
    const response = await staffAuthRequest(GYM_USER_URL, {
      method: "POST",
      body: JSON.stringify(userWithSlug),
    });
    return response;
  }, [slug, staffAuthRequest]);

  const updateGymUser = useCallback(async (id, user) => {
    console.log("Updating password with data:", user);

    const response = await staffAuthRequest(`${GYM_USER_URL}/${id}?slug=${slug}`, {
      method: "PUT",
      body: JSON.stringify(user),
    });
    return response;
  }, [slug, staffAuthRequest]);

  const deleteGymUser = useCallback(async (id) => {
    const response = await staffAuthRequest(`${GYM_USER_URL}/${id}`, {
      method: "DELETE",
    });
    return response;
  }, [staffAuthRequest]);

  return {
    getGymUsers,
    getGymUser,
    addGymUser,
    updateGymUser,
    deleteGymUser
  };
};
