import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Table } from "react-bootstrap";
import useStaffAuthRequest from "../../hooks/useStaffAuthRequest";
import { API_URL } from "../../config";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import "./Gym.css";

const GymMittWorkSummary = () => {
  const [monthlySummary, setMonthlySummary] = useState([]);
  const [dailySummary, setDailySummary] = useState([]);
  const staffAuthRequest = useStaffAuthRequest();
  const { slug } = useParams();
  const [selectedDate, setSelectedDate] = useState(null); // 選択された日付を保持するための新しい状態

  // 現在のUTC年と月を取得
  // const currentUTCDate = new Date();
  // const currentUTCYear = currentUTCDate.getUTCFullYear();
  // const currentUTCMonth = currentUTCDate.getUTCMonth() + 1; // 月は0から始まるため、1を足す
    // ブラウザのタイムゾーンに基づいて現在の年月を取得
  const currentTimeZoneDate = new Date().toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const currentYear = new Date(currentTimeZoneDate).getFullYear();
  const currentMonth = new Date(currentTimeZoneDate).getMonth() + 1;

  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  

  // 月間ランキングデータを取得する関数
  const fetchMonthlySummary = useCallback(async () => {
    try {
      const formattedDate = `${year}-${String(month).padStart(2, "0")}-01`;
      const response = await staffAuthRequest(
        `${API_URL}/api/v1/gym/attendances/mitt_work_summary?slug=${slug}&type=monthly&date=${formattedDate}`
      );
      // APIからのレスポンスを配列に変換
      const summaryArray = Object.entries(response.data);
      // 配列をuseStateにセット
      setMonthlySummary(summaryArray);
    } catch (error) {
      console.error("Error fetching monthly data: ", error);
    }
  }, [staffAuthRequest, slug, year, month]);

  const fetchDailySummary = useCallback(
    async (date) => {
      try {
        const response = await staffAuthRequest(
          `${API_URL}/api/v1/gym/attendances/mitt_work_summary?slug=${slug}&type=daily&date=${date}`
        );
        // オブジェクトから配列に変換
        const summaryArray = Object.entries(response.data).map(
          ([trainerName, count]) => ({ trainerName, count })
        );
        setDailySummary(summaryArray);
      } catch (error) {
        console.error("Error fetching daily data: ", error);
      }
    },
    [staffAuthRequest, slug]
  );

  useEffect(() => {
    fetchMonthlySummary();
  }, [fetchMonthlySummary]);

  // 日付クリックハンドラ
  const handleDateClick = (date) => {
    // UTC日付に変換
    const dateInUTC = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    // ISO文字列に変換
    const isoDateString = dateInUTC.toISOString().split("T")[0];
    // データをフェッチ
    fetchDailySummary(isoDateString);
    setSelectedDate(date);
  };

  // 月切り替えハンドラ
  const handleMonthChange = (change) => {
    let newMonth = month + change;
    let newYear = year;

    if (newMonth > 12) {
      newMonth = 1;
      newYear += 1;
    } else if (newMonth < 1) {
      newMonth = 12;
      newYear -= 1;
    }

    setMonth(newMonth);
    setYear(newYear);
  };

  // 今月に戻る関数
  const resetToCurrentMonth = () => {
    setYear(currentYear);
    setMonth(currentMonth);
  };

  // 現在表示されている月が現在の月かどうかを判断する関数
  const isCurrentMonthDisplayed = () => {
    return year === currentYear && month === currentMonth;
  };

  // カレンダーを生成する関数
  const generateCalendar = () => {
    // 月の最初の日と最後の日を取得
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);

    // カレンダーの開始日を決定
    // 月の最初の日が日曜日でなければ、その週の日曜日まで遡る
    while (startDate.getDay() !== 0) {
      startDate.setDate(startDate.getDate() - 1);
    }

    const days = [];

    // 開始日から月末までの日付を配列に追加
    for (
      let day = new Date(startDate);
      day <= endDate;
      day.setDate(day.getDate() + 1)
    ) {
      days.push(new Date(day));
    }

    return days;
  };

  const days = generateCalendar();

  return (
    <div className="gym-top-page">
      <GymHeader />
      <Container className="gym-card">
        {/* ヘッダーとボタン */}
        <div className="mitt-header-and-buttons">
          <h2>
            {year}年{month}月のランキング
          </h2>
          <div className="mitt-month-navigation-buttons">
            <Button
              variant="outline-primary"
              onClick={() => handleMonthChange(-1)}
            >
              前月
            </Button>
            <Button variant="outline-primary" onClick={resetToCurrentMonth}>
              今月
            </Button>
            {!isCurrentMonthDisplayed() && (
              <Button
                variant="outline-primary"
                onClick={() => handleMonthChange(1)}
              >
                次月
              </Button>
            )}
          </div>
        </div>
        
        {/* 月間ランキングテーブル */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>順位</th>
              <th>トレーナー名</th>
              <th>回数</th>
            </tr>
          </thead>
          <tbody>
            {monthlySummary.map(([trainerName, count], index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{trainerName}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
  
        {/* カレンダー */}
        <div className="mitt-work-summary-calendar">
          <div className="mitt-work-calendar-header">
            {["日", "月", "火", "水", "木", "金", "土"].map((day, index) => (
              <div key={index} className="mitt-work-calendar-day-header">
                {day}
              </div>
            ))}
          </div>
          <div className="mitt-work-calendar-grid">
            {days.map((day, index) => (
              <div
                key={index}
                className="mitt-work-calendar-day"
                onClick={() => handleDateClick(day)}
              >
                {day.getDate()}
              </div>
            ))}
          </div>
        </div>
  
        {/* 日間ランキング表示 */}
        <div>
          <h2>
            {selectedDate
              ? `${selectedDate.getFullYear()}年${
                  selectedDate.getMonth() + 1
                }月${selectedDate.getDate()}日のランキング`
              : "日付を選択してください"}
          </h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>順位</th>
                <th>トレーナー名</th>
                <th>回数</th>
              </tr>
            </thead>
            <tbody>
              {dailySummary.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.trainerName}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
      <GymFooter />
    </div>
  );
  
  
};

export default GymMittWorkSummary;
