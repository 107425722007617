import React, { createContext, useContext, useState, useEffect } from "react";

export const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const client = localStorage.getItem("client");
    const uid = localStorage.getItem("uid");

    if (accessToken && client && uid) {
      setAuthUser({ accessToken, client, uid });
    }
    setLoading(false);
  }, []);

  const login = ({ accessToken, client, uid }) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("client", client);
    localStorage.setItem("uid", uid);
    setAuthUser({ accessToken, client, uid });
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("client");
    localStorage.removeItem("uid");
    setAuthUser(null);
  };

  const value = {
    authUser,
    loading,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
