import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import LoadingIndicator from '../../LoadingIndicator';


const PrivateRoute = ({ children }) => {
  const { authUser, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    // 読み込み中は何も表示しない、またはローディングインジケーターを表示する
    return <LoadingIndicator />;
  }
  
  console.log('PrivateRoute authUser:', authUser); // ここで authUser の状態を確認

  if (!authUser) {
    console.log('No authUser, redirecting to login.'); // ここでリダイレクトが行われることを確認
    // 認証されていない場合はログインページにリダイレクト
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  console.log('authUser exists, displaying children.'); // authUser が存在する場合のログ
  return children;
};

export default PrivateRoute;
