import React from 'react';
import { useLocation } from 'react-router-dom';

const ErrorPage = () => {
  const location = useLocation();
  const { status } = location.state || { status: 'Error' };

  const renderErrorMessage = () => {
    switch (status) {
      case 401:
        return 'アクセス権限がありません。';
      case 404:
        return 'お探しのページが見つかりません。';
      default:
        return '予期しないエラーが発生しました。';
    }
  };

  return (
    <div>
      <h1>Error: {status}</h1>
      <p>{renderErrorMessage()}</p>
    </div>
  );
};

export default ErrorPage;
