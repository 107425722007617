import React from "react";
import "./TopPage.css"; // CSSファイルをインポート

function TopPage() {
  const gyms = [];

  return (
    <div className="top-page">
      <div className="logo-container">
        <h1>box-ing.fun</h1>
      </div>
      <h2>Enhance Your Life with Boxing</h2>
      <p>Discover a new way to enjoy fitness and boxing in a luxurious environment.</p>
      <div className="gym-list-container">
        <table className="table-gyms">
          <thead>
            <tr>
              <th>ジム名</th>
              <th>郵便番号</th>
              <th>住所</th>
              <th>電話番号</th>
            </tr>
          </thead>
          <tbody>
            {gyms.map((gym, index) => (
              <tr key={index}>
                <td>{gym.name}</td>
                <td>{gym.postalCode}</td>
                <td>{gym.address}</td>
                <td>{gym.phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopPage;
