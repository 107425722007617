import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { API_URL } from "../../config";
import { useMemberAuth } from "../../contexts/MemberAuthContext";
import "./Member.css"; // Member.cssをインポート

const GymMemberLogin = () => {
  const [memberId, setMemberId] = useState("");
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { slug } = useParams();
  const { login } = useMemberAuth();
  const [gymName, setGymName] = useState("");

  useEffect(() => {
    const fetchGymData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/gyms/find_by_slug?slug=${slug}`);

        if (!response.ok) {
          if (response.status === 404) {
            navigate('/error', { state: { status: 404 } }); // 共通エラーページに状態を渡す
            return;
          }
          throw new Error('Failed to fetch gym data');
        }

        const data = await response.json();
        setGymName(data.name);
      } catch (error) {
        console.error("ジムデータの取得に失敗しました:", error);
      }
    };
    
    fetchGymData();
  }, [slug, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(`${API_URL}/member_auth/sign_in`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ member_id: memberId, tel, password, slug }),
      });

      if (!response.ok) {
        throw new Error("ログインに失敗しました。");
      }
      const accessToken = response.headers.get("access-token");
      const client = response.headers.get("client");
      const uid = response.headers.get("uid");

      login({
        memberAccessToken: accessToken,
        memberClient: client,
        memberUid: uid,
      });
      navigate(`/gyms/${slug}/members/dashboard`);
    } catch (error) {
      setError(
        "ログインに失敗しました。会員ID、電話番号、またはパスワードが正しくありません。"
      );
    }
  };

  return (
    <div className="member">
      <div className="member-card">
        <div className="member-body">
          {gymName && <h1 className="member-login-gym-name text-center">{gymName}</h1>}
          <h2 className="member-header text-center">Member Enter...</h2>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form onSubmit={handleLogin}>
            <Form.Group id="memberId" className="member-form-group">
              <Form.Label>会員ID</Form.Label>
              <Form.Control
                type="text"
                required
                value={memberId}
                onChange={(e) => setMemberId(e.target.value)}
                className="member-form-control"
              />
            </Form.Group>
            <Form.Group id="tel" className="member-form-group">
              <Form.Label>電話番号</Form.Label>
              <Form.Control
                type="text"
                required
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                className="member-form-control"
              />
            </Form.Group>
            <Form.Group id="password" className="member-form-group">
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                className="member-form-control"
              />
            </Form.Group>
            <Button className="member-button w-100 mt-3" type="submit">
              Go! Box!! 🥊
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default GymMemberLogin;
