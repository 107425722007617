import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useGymUserService } from "../../services/gymUserService";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import "./Gym.css";

function GymUserAdd() {
  const { slug } = useParams();
  const { addGymUser } = useGymUserService(slug);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: "",
    name_kana: "",
    tel: "",
    stance: "right",
    birthday: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addGymUser(userData);
    navigate(`/gyms/${slug}/users`);
  };

  const handleCancel = () => {
    navigate(`/gyms/${slug}/users`);
  };

  return (
    <div className="gym-top-page">
      {" "}
      {/* GymUserEditと同じラッパーを使用 */}
      <GymHeader />
      <Container className="edit-user-container">
        {" "}
        {/* GymUserEditと同じクラス名を使用 */}
        <h2 className="my-4 gym-header sticky-header">新規会員追加</h2>
        {/* タイトルスタイルの適用 */}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="form-group-spacing">
            <Form.Label>氏名</Form.Label>
            <Form.Control
              type="text"
              required
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formNameKana" className="form-group-spacing">
            <Form.Label>氏名カナ</Form.Label>
            <Form.Control
              type="text"
              required
              name="name_kana"
              value={userData.name_kana}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formTel" className="form-group-spacing">
            <Form.Label>Tel</Form.Label>
            <Form.Control
              type="tel"
              required
              name="tel"
              value={userData.tel}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formHandedness" className="form-group-spacing">
            <Form.Label>構え</Form.Label>
            <Form.Control
              as="select"
              name="stance"
              value={userData.stance}
              onChange={handleChange}
            >
              <option value="right">オーソドックス（右）</option>
              <option value="left">サウスポー（左）</option>
              <option value="both">両方</option>
              <option value="unknown">不明</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formBirthday" className="form-group-spacing">
            <Form.Label>生年月日</Form.Label>
            <Form.Control
              type="date"
              required
              name="birthday"
              value={userData.birthday}
              onChange={handleChange}
            />
          </Form.Group>

          <div className="button-group">
            <Button variant="primary" type="submit" className="me-2">
              追加
            </Button>
            <Button variant="outline-secondary" onClick={handleCancel}>
              戻る
            </Button>
          </div>
        </Form>
      </Container>
      <GymFooter />
    </div>
  );
}

export default GymUserAdd;
