import { useCallback } from "react";
import useStaffAuthRequest from "../hooks/useStaffAuthRequest";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";

const GYM_STAFF_URL = `${API_URL}/api/v1/gym/staffs`;

export const useGymStaffService = (slug) => {
  const navigate = useNavigate();

  const staffAuthRequest = useStaffAuthRequest();

  const handleError = useCallback(
    (error) => {
      navigate("/error", { state: { status: error.status || "Error" } });
    },
    [navigate]
  );

  const getGymStaffs = useCallback(async () => {
    try {
      const response = await staffAuthRequest(`${GYM_STAFF_URL}?slug=${slug}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  }, [handleError, slug, staffAuthRequest]);

  const getGymStaff = useCallback(
    async (id) => {
      try {
        const response = await staffAuthRequest(`${GYM_STAFF_URL}/${id}?slug=${slug}`);
        return response;
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, slug, staffAuthRequest]
  );

  const addGymStaff = useCallback(
    async (staffData) => {
      try {
        const response = await staffAuthRequest(GYM_STAFF_URL, {
          method: "POST",
          body: JSON.stringify(staffData),
        });
        return response;
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, staffAuthRequest]
  );

  const updateGymStaff = useCallback(
    async (id, staff) => {
      try {
        console.log("Updating staff with data:", staff);

        const response = await staffAuthRequest(`${GYM_STAFF_URL}/${id}?slug=${slug}`, {
          method: "PUT",
          body: JSON.stringify(staff),
        });
        return response;
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, slug, staffAuthRequest]
  );

  const deleteGymStaff = useCallback(
    async (id) => {
      try {
        const response = await staffAuthRequest(`${GYM_STAFF_URL}/${id}`, {
          method: "DELETE",
        });
        return response;
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, staffAuthRequest]
  );

  return {
    getGymStaffs,
    getGymStaff,
    addGymStaff,
    updateGymStaff,
    deleteGymStaff,
  };
};
