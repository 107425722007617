import React, { useEffect, useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDay,
  addMonths,
  isFuture,
} from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";

import { API_URL } from "../../config";
import "./Calendar.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useMemberAuthRequest from '../../hooks/useMemberAuthRequest';
import MemberFooter from "./MemberFooter";

function MemberTop() {
  const [attendances, setAttendances] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [summary, setSummary] = useState({});
  const [userName, setUserName] = useState("");
  const [gymName, setGymName] = useState("");
  const [memberId, setMemberId] = useState("");
  const [weightsData, setWeightsData] = useState([]);
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const navigate = useNavigate();
  const memberAuthRequest = useMemberAuthRequest();
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedDate = format(currentMonth, "yyyy-MM");
        const response = await memberAuthRequest(`${API_URL}/api/v1/members/dashboards?date=${formattedDate}&slug=${slug}`);
        setAttendances(response.attendances);
        setSummary(response.summary);
        setUserName(response.user_name);
        setWeightsData(response.weights_data);
        setActiveUsersCount(response.active_users_count);
        setGymName(response.gym_name);
        setMemberId(response.member_id);
      } catch (error) {
        console.error('Error fetching attendances:', error);
        navigate('/error', { state: { status: error.status || 'Error' } });
      }
    };

    fetchData();
  }, [currentMonth, navigate, memberAuthRequest, slug]); // 依存配列を更新

  function WeightChart({ weightsData }) {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={weightsData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="pre_weight"
            stroke="#8884d8"
            name="練習前の体重"
          />
          <Line
            type="monotone"
            dataKey="post_weight"
            stroke="#82ca9d"
            name="練習後の体重"
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  const firstDay = startOfMonth(currentMonth);
  const lastDay = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: firstDay, end: lastDay });
  const adjustedDays = Array(getDay(firstDay)).fill(null).concat(daysInMonth);

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, -1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, 1));
  };

  return (
    <div className="calendar">
    <div className="gym-and-user-info">
        <div className="user-count">{gymName} 現在のご利用者数: {activeUsersCount} 人</div>
        <div className="user-name">メンバーID: {memberId}　会員名: {userName} 様</div>
      </div>
      <div className="header" style={{ height: '70px' }}>
        <button onClick={handlePrevMonth}>&lt;</button>
        <span>{format(currentMonth, "MMMM yyyy")}</span>
        {!isFuture(endOfMonth(currentMonth)) ? (
          <button onClick={handleNextMonth}>&gt;</button>
        ) : (
          <div className="button-placeholder"></div>
        )}
      </div>
      <div className="days">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="day day-header">
            {day}
          </div>
        ))}
        {adjustedDays.map((day, index) => {
          if (!day) {
            return <div key={index} className="day"></div>;
          }
          const dayKey = format(day, "yyyy-MM-dd");
          const attendance = attendances.find((a) => a.check_in === dayKey);
          return (
            <div key={dayKey} className="day">
              {attendance ? (
                <Link
                  to={`/gyms/${slug}/members/practice_menu/${attendance.id}`}
                  className="attendance-link"
                >
                  {format(day, "d")}
                </Link>
              ) : (
                format(day, "d")
              )}
            </div>
          );
        })}
      </div>
      <div className="practice-count">
        練習日数: {attendances.length}日
      </div>
      <div className="practice-summary">
        <div>縄跳びの合計: {summary.skipping_rope_sum} R</div>
        <div>シャドーボクシングの合計: {summary.shadow_boxing_sum} R</div>
        <div>サンドバッグの合計: {summary.sand_bag_sum} R</div>
        <div>ミットの合計: {summary.mitt_practice_sum} R</div>
        <div>スパーリングの合計: {summary.sparring_sum} R</div>
        <div>ロードワークの合計: {summary.roadwork_sum} Km</div>
      </div>
      <div className="weight-chart">
        <WeightChart weightsData={weightsData} />
      </div>
    <MemberFooter />
    </div>
  );
}

export default MemberTop;
