import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

function AddStaff() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [birth, setBirth] = useState("");
  const [gender, setGender] = useState("");
  const [career, setCareer] = useState("");
  const [hobby, setHobby] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [isRetired, setIsRetired] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const staff = {
      name,
      name_en: nameEn,
      birth,
      gender,
      career,
      hobby,
      is_working: isWorking,
      is_retired: isRetired,
      email,
      phone,
      password,
      password_confirmation: password,
    };
    try {
      const response = await axios.post(`${API_URL}/api/v1/staffs`, { staff });

      if (response.data.status === "ERROR") {
        console.error("There was an error!", response.data.message);
        return;
      }

      navigate("/staffs"); // 登録後にスタッフ一覧にリダイレクト
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <Container>
      <h2 className="my-4">スタッフ追加</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formNameEn">
          <Form.Label>Name (English)</Form.Label>
          <Form.Control
            type="text"
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formPhone">
          <Form.Label>電話</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBirth">
          <Form.Label>Birthdate</Form.Label>
          <Form.Control
            type="date"
            value={birth}
            onChange={(e) => setBirth(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formGender">
          <Form.Label>Gender</Form.Label>
          <Form.Select
            aria-label="Gender select"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Select...</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formCareer">
          <Form.Label>Career</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={career}
            onChange={(e) => setCareer(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formHobby">
          <Form.Label>Hobby</Form.Label>
          <Form.Control
            type="text"
            value={hobby}
            onChange={(e) => setHobby(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formIsWorking">
          <Form.Check
            type="checkbox"
            label="Is Working"
            checked={isWorking}
            onChange={(e) => setIsWorking(e.target.checked)}
          />
        </Form.Group>

        <Form.Group controlId="formIsRetired">
          <Form.Check
            type="checkbox"
            label="Is Retired"
            checked={isRetired}
            onChange={(e) => setIsRetired(e.target.checked)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>パスワード</Form.Label>
          <Form.Control
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}

export default AddStaff;
