import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import { API_URL } from "../config";

function EditStaff() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [nameEn, setNameEn] = useState(null);
  const [birth, setBirth] = useState(null);
  const [gender, setGender] = useState(null);
  const [career, setCareer] = useState(null);
  const [hobby, setHobby] = useState(null);
  const [isWorking, setIsWorking] = useState(null);
  const [isRetired, setIsRetired] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    axios.get(`${API_URL}/api/v1/staffs/${id}`).then((response) => {
      const staff = response.data.data;
      setName(staff.name);
      setNameEn(staff.name_en);
      setBirth(staff.birth);
      setGender(staff.gender);
      setCareer(staff.career);
      setHobby(staff.hobby);
      setIsWorking(staff.is_working);
      setIsRetired(staff.is_retired);
      setEmail(staff.email);
      setPhone(staff.phone);
      setPassword(staff.password);
    });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const staff = {
      name,
      name_en: nameEn,
      birth,
      gender,
      career,
      hobby,
      is_working: isWorking,
      is_retired: isRetired,
      email,
      phone,
      password,
      password_confirmation: password,
    };
    try {
      await axios.put(`${API_URL}/api/v1/staffs/${id}`, { staff });
      navigate("/staffs");
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  if (
    name === null ||
    nameEn === null ||
    birth === null ||
    gender === null ||
    career === null ||
    hobby === null ||
    isWorking === null ||
    isRetired === null
  ) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <h1>Edit Staff</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formNameEn">
          <Form.Label>Name (English)</Form.Label>
          <Form.Control
            type="text"
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formPhone">
          <Form.Label>電話</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBirth">
          <Form.Label>Birthdate</Form.Label>
          <Form.Control
            type="date"
            value={birth}
            onChange={(e) => setBirth(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formGender">
          <Form.Label>Gender</Form.Label>
          <Form.Select
            aria-label="Gender select"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Select...</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formCareer">
          <Form.Label>Career</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={career}
            onChange={(e) => setCareer(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formHobby">
          <Form.Label>Hobby</Form.Label>
          <Form.Control
            type="text"
            value={hobby}
            onChange={(e) => setHobby(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formIsWorking">
          <Form.Check
            type="checkbox"
            label="Is Working"
            checked={isWorking}
            onChange={(e) => setIsWorking(e.target.checked)}
          />
        </Form.Group>

        <Form.Group controlId="formIsRetired">
          <Form.Check
            type="checkbox"
            label="Is Retired"
            checked={isRetired}
            onChange={(e) => setIsRetired(e.target.checked)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>パスワード</Form.Label>
          <Form.Control
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}

export default EditStaff;
