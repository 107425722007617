import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Table, Button, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { API_URL } from "./config";
import Footer from "./Footer/Footer"; // Import Footer

function Rankings() {
  const [rankings, setRankings] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // JavaScript months are 0-based

  const fetchRankings = useCallback(async () => {
    const response = await axios.get(
      `${API_URL}/api/v1/rankings/attendance`,
      {
        params: { year, month },
      }
    );
    setRankings(response.data);
  }, [year, month]);

  useEffect(() => {
    fetchRankings();
  }, [fetchRankings]);

  const handlePrevMonth = () => {
    if (month > 1) {
      setMonth(month - 1);
    } else {
      setMonth(12);
      setYear(year - 1);
    }
  };

  const handleNextMonth = () => {
    if (month < 12) {
      setMonth(month + 1);
    } else {
      setMonth(1);
      setYear(year + 1);
    }
  };

  const handleCurrentMonth = () => {
    const currentDate = new Date();
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth() + 1);  // JavaScript months are 0-based
  };

  return (
    <Container>
      <h1 className="my-4">Attendance Rankings</h1> {/* Header */}
      <Button onClick={handlePrevMonth}>←</Button>
      <Button onClick={handleCurrentMonth}>今月</Button>
      <Button onClick={handleNextMonth}>→</Button>
      <span className="mx-4">{year}年{month}月</span> {/* Displaying current year and month */}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>ランキング</th>
            <th>名前</th>
            <th>回数</th>
          </tr>
        </thead>
        <tbody>
          {rankings.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.attendance_count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Footer /> {/* Footer */}
    </Container>
  );
}

export default Rankings;
