import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import { API_URL } from "../../config";
import { useStaffAuth } from "../../contexts/StaffAuthContext";
import "./Gym.css";

const GymStaffLogin = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { slug } = useParams();
  const { login } = useStaffAuth();
  const [gymName, setGymName] = useState("");
  const [mode, setMode] = useState(""); // モードのステートを追加

  useEffect(() => {
    const fetchGymData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/gyms/find_by_slug?slug=${slug}`);

        if (!response.ok) {
          if (response.status === 404) {
            navigate("/error", { state: { status: 404 } }); // 共通エラーページに状態を渡す
            return;
          }
          throw new Error("Failed to fetch gym data");
        }

        const data = await response.json();
        setGymName(data.name);
      } catch (error) {
        console.error("ジムデータの取得に失敗しました:", error);
      }
    };

    fetchGymData();
  }, [slug, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch(`${API_URL}/gym_auth/sign_in`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone, password, slug }),
      });

      if (!response.ok) {
        throw new Error("ログインに失敗しました。");
      }

      const accessToken = response.headers.get("access-token");
      const client = response.headers.get("client");
      const uid = response.headers.get("uid");

      login({ staffAccessToken: accessToken, staffClient: client, staffUid: uid, mode });

      navigate(`/gyms/${slug}/top`);
    } catch (error) {
      setError("ログインに失敗しました。電話番号またはパスワードが正しくありません。");
    }
  };

  return (
    <div className="gym-top-page">
      <div className="gym-name-header">
        {gymName && <h1 className="gym-header text-center">{gymName}</h1>}
      </div>
      <Card className="gym-login-card">
        <Card.Body>
          <h2>Staff Enter...</h2>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form onSubmit={handleLogin}>
            <Form.Group id="phone">
              <Form.Label>tel</Form.Label>
              <Form.Control
                type="text"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>pass</Form.Label>
              <Form.Control
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autocomplete="new-password"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>manage? or member?</Form.Label>
              <Form.Control
                as="select"
                required
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value="">選択してください</option>
                <option value="admin">管理モード</option>
                <option value="member">会員受付モード</option>
              </Form.Control>
            </Form.Group>
            <Button className="gym-button w-100 mt-3" type="submit">
              Go! Box!! 🥊
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GymStaffLogin;
