import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Container, Button } from 'react-bootstrap'; // react-bootstrap のコンポーネントを追加
// import { getUsers, deleteUser } from '../services/userService';
import { getUsers } from '../services/userService';
import Footer from "../Footer/Footer";

function UserList() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setUsers(data);
    }

    fetchData();
  }, []);

  // const handleDelete = async (id) => {
  //   await deleteUser(id);
  //   setUsers(users.filter(user => user.id !== id));
  // }

  return (
    <Container>
      <h2 className="my-4">会員様一覧</h2>
      <Link to="/users/new">
        <Button variant="primary" type="button" className="mb-4">
          Add User
        </Button>
      </Link>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Tel</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.tel}</td>
              <td>
                <Link to={`/users/edit/${user.id}`}>Edit</Link>
                {' '} {/* スペースを追加 */}
                {/* <Button variant="danger" onClick={() => handleDelete(user.id)}>Delete</Button> Bootstrap のボタンスタイルを追加 */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Footer />
    </Container>
  );
}

export default UserList;
