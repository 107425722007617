import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Button, Container, Form } from "react-bootstrap"; // ここにFormを追加
import { Link } from "react-router-dom";
import { API_URL } from './config';
import Footer from "./Footer/Footer";

function Staffs() {
  const [staffs, setStaffs] = useState([]);

  const fetchStaffs = async () => {  // この関数をStaffs関数の中に移動させる
    const response = await axios.get(`${API_URL}/api/v1/staffs`);
    setStaffs(response.data.data);
  };

  useEffect(() => {
    fetchStaffs();
  }, []);

  const handleWorkingStatusChange = async (staff) => {
    await axios.put(`${API_URL}/api/v1/staffs/${staff.id}`, {
      staff: {
        is_working: !staff.is_working,
      },
    });
    fetchStaffs();
  };
  
  // const handleDelete = async (id) => {
  //   if (window.confirm('削除してもよろしいですか？')) {
  //     await axios.delete(`${API_URL}/api/v1/staffs/${id}`);
  //     fetchStaffs();
  //   }
  // };

  return (
    <Container>
      <h1 className="my-4">Staff Management</h1>
      <Link to="/staffs/new">
        <Button variant="primary" type="button">
          Add Staff
        </Button>
      </Link>
      <Link to="/staffs/mitt_practice_status">  {/* Add this line */}
        <Button variant="info" type="button">
          Mitt Practice Status
        </Button>
      </Link>  {/* And this line */}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>出勤中</th>
            <th>氏名</th>
            <th>Name</th>
            <th>生年月日</th>
            <th>性別</th>
            <th>趣味</th>
            <th>経歴</th>
            <th>出勤中？</th>
            <th>退職済み？</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {staffs.map((staff) => (
            <tr key={staff.id}>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={staff.is_working}
                  onChange={() => handleWorkingStatusChange(staff)}
                />
              </td>
              <td>{staff.name}</td>
              <td>{staff.name_en}</td>
              <td>{staff.birth}</td>
              <td>{staff.gender}</td>
              <td>{staff.hobby}</td>
              <td>{staff.career}</td>
              <td>{staff.is_working ? "Yes" : "No"}</td>
              <td>{staff.is_retired ? "Yes" : "No"}</td>
              <td>
                <Link to={`/staffs/edit/${staff.id}`}>
                  <Button variant="warning" size="sm" className="mr-2">
                    Edit
                  </Button>{" "}
                  {/* Editボタンを追加 */}
                </Link>
                {/* <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(staff.id)}
                >
                  Delete
                </Button>{" "} */}
                {/* Deleteボタンを追加 */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Footer />
    </Container>
  );
}

export default Staffs;
