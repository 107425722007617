import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Dropdown, Container, Table } from "react-bootstrap";
import { API_URL } from "../../config";
import { useParams, useNavigate } from "react-router-dom";
import useStaffAuthRequest from "../../hooks/useStaffAuthRequest";
import { useStaffAuth } from "../../contexts/StaffAuthContext";
import "./Gym.css";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import LoadingDots from "../common/LoadingDots";

function GymTopPage() {
  const [isLoading, setIsLoading] = useState(true);
  const { staffAuthUser } = useStaffAuth();
  const navigate = useNavigate();
  const [gym, setGym] = useState(null);
  const { slug } = useParams();
  const staffAuthRequest = useStaffAuthRequest();
  const [attendances, setAttendances] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserNameKana, setSelectedUserNameKana] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedAttendanceId, setSelectedAttendanceId] = useState(null);
  const [monthlyAttendanceCount, setMonthlyAttendanceCount] = useState(0);
  const [weeklyAttendance, setWeeklyAttendance] = useState([]);

  const { date } = useParams();
  const nowInTokyo = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Tokyo" }));
  const initialDate = date ? new Date(date) : nowInTokyo;
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [memberNumber, setMemberNumber] = useState("");
  const [checkinPassword, setCheckinPassword] = useState("");
  const [selectedUserAge, setSelectedUserAge] = useState("-");
  const [selectedUserIssues, setSelectedUserIssues] = useState("");
  const [selectedUserStance, setSelectedUserStance] = useState("");
  const [selectedUserBoxingExperience, setSelectedUserBoxingExperience] = useState("");

  const handleCheckinModalClose = () => setShowCheckinModal(false);
  const handleCheckinModalShow = () => {
    setMemberNumber("");
    setCheckinPassword("");
    setShowCheckinModal(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = (attendance) => {
    setSelectedUserName(attendance.user_name);
    setSelectedUserNameKana(attendance.user_name_kana);
    setSelectedUserAge(attendance.user_age);
    setSelectedUserIssues(attendance.user_issues);
    setSelectedUserStance(attendance.user_stance);
    setSelectedUserBoxingExperience(attendance.user_boxing_experience);
    setSelectedAttendanceId(attendance.id);
    if (attendance.user_id) {
      fetchMonthlyAttendanceCount(attendance.user_id);
      fetchWeeklyAttendance(attendance.user_id);
    }
    setShow(true);
  };

  const handleStaffSelect = (staff) => {
    const selected = staffs.find((s) => s.name === staff);
    setSelectedStaff(selected);
  };

  const fetchWeeklyAttendance = async (userId) => {
    try {
      const response = await staffAuthRequest(
        `${API_URL}/api/v1/gym/attendances/${userId}/weekly_user_attendance?slug=${slug}`
      );
      if (response.status === "SUCCESS") {
        setWeeklyAttendance(response.data);
      } else {
        console.error("Failed to fetch weekly attendance:", response.message);
        // 必要に応じてユーザーに通知する
      }
    } catch (error) {
      console.error("Error fetching weekly attendance:", error);
      // 必要に応じてユーザーにエラーを通知する
    }
  };

  const fetchMonthlyAttendanceCount = async (userId) => {
    try {
      const data = await staffAuthRequest(
        `${API_URL}/api/v1/gym/users/${userId}/attendances/monthly_user_attendance_count?slug=${slug}`
      );
      if (data.status === "SUCCESS") {
        setMonthlyAttendanceCount(data.data.count);
      } else {
        console.error("Failed to fetch monthly attendance count");
        setMonthlyAttendanceCount("?");
      }
    } catch (error) {
      console.error("Error fetching monthly attendance count", error);
    }
  };

  const formatDay = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("ja-JP", { day: "numeric" }).format(date);
  };

  const handleAssign = () => {
    if (!selectedStaff) {
      alert("スタッフを選択してください。");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        assignment: {
          attendance_id: selectedAttendanceId,
          staff_id: selectedStaff.id,
        },
      }),
    };
    staffAuthRequest(`${API_URL}/api/v1/gym/assignments?slug=${slug}`, requestOptions).then(
      (data) => {
        setRefresh(!refresh);
      }
    );

    setShow(false);
  };

  const handlePreviousDay = () => {
    let prevDay = new Date(selectedDate);
    prevDay.setDate(prevDay.getDate() - 1);
    setSelectedDate(prevDay);
    setRefresh(!refresh);
  };

  const handleNextDay = () => {
    let nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setSelectedDate(nextDate);
  };

  const handleToday = () => {
    let todayDate = new Date();
    setSelectedDate(todayDate);
    setRefresh(!refresh);
  };

  const handleAttendanceSubmit = (attendanceUserId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        memberNumber,
        checkinPassword,
        slug,
      }),
    };
    const endpoint = attendanceUserId
      ? `${API_URL}/api/v1/gym/users/${attendanceUserId}/attendances`
      : `${API_URL}/api/v1/gym/users/${memberNumber}/attendances`;

    staffAuthRequest(`${endpoint}`, requestOptions)
      .then((data) => {
        if (data.status === "SUCCESS") {
          alert(data.message);
          setRefresh(!refresh); // リストをリフレッシュ
          handleCheckinModalClose(); // モーダルを閉じる
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        alert("操作中にエラーが発生しました: " + error.message);
      });
  };

  const [showCompletionMessage, setShowCompletionMessage] = useState(false);
  const [completionMessagePosition, setCompletionMessagePosition] = useState({
    top: 0,
    left: 0,
  });

  const handleMittPracticeCompletedChange = (attendanceId, event) => {
    const isChecked = event.target.checked;
    const checkboxRect = event.target.getBoundingClientRect();
    setCompletionMessagePosition({
      top: checkboxRect.top + window.scrollY - 20, // チェックボックスの下に少しスペースを空ける
      left: checkboxRect.left + window.scrollX,
    });

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        mitt_practice_completed: !attendances.find((a) => a.id === attendanceId)
          .mitt_practice_completed,
      }),
    };

    staffAuthRequest(
      `${API_URL}/api/v1/gym/attendances/${attendanceId}?slug=${slug}`,
      requestOptions
    )
      .then((data) => {
        // レスポンスを確認し、状態を更新するか、エラーメッセージを表示する
        if (data.status === "SUCCESS") {
          setRefresh(!refresh);
          if (isChecked) {
            // Show message only if checkbox is checked
            setShowCompletionMessage(true);
            setTimeout(() => setShowCompletionMessage(false), 1500);
          }
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        alert("操作中にエラーが発生しました: " + error.message);
      });
  };

  useEffect(() => {
    // const formattedDate = new Intl.DateTimeFormat("en-CA", {
    //   timeZone: "Asia/Tokyo",
    // }).format(selectedDate);

    const formatDateToYYYYMMDD = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const formattedDate = formatDateToYYYYMMDD(selectedDate);

    const fetchAttendances = async () => {
      try {
        const response = await staffAuthRequest(
          `${API_URL}/api/v1/gym/attendances?date=${formattedDate}&slug=${slug}`
        );
        setAttendances(response.data);
      } catch (error) {
        console.error("Error fetching attendances:", error);
        redirectToErrorPage(error);
      }
    };

    const fetchStaffs = async () => {
      try {
        const response = await staffAuthRequest(
          `${API_URL}/api/v1/gym/staffs/working?slug=${slug}`
        );
        setStaffs(response.data);
      } catch (error) {
        console.error("Error fetching staffs:", error);
        redirectToErrorPage(error);
      }
    };

    const fetchGymData = async () => {
      try {
        const data = await staffAuthRequest(`${API_URL}/api/v1/gym/top_page/${slug}`);
        setGym(data);
      } catch (error) {
        console.error("Error fetching gym data:", error);
        redirectToErrorPage(error);
      }
    };

    const redirectToErrorPage = (error) => {
      navigate("/error", { state: { status: error.status || "Error" } });
    };
    const fetchAllData = async () => {
      try {
        setIsLoading(true); // データフェッチ前にローディング開始
        await Promise.all([fetchAttendances(), fetchStaffs(), fetchGymData()]);
      } catch (error) {
        console.error("Error in fetching data:", error);
        // エラーハンドリング（必要に応じて）
      } finally {
        setIsLoading(false); // 全てのデータがフェッチされたらローディング終了
      }
    };
    fetchAllData();
  }, [
    navigate,
    refresh,
    selectedDate,
    slug,
    staffAuthRequest,
    monthlyAttendanceCount,
    weeklyAttendance,
  ]);

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="gym-top-page">
      {showCompletionMessage && (
        <div
          className="completion-message"
          style={{
            top: `${completionMessagePosition.top}px`,
            left: `${completionMessagePosition.left}px`,
          }}
        >
          🥊
        </div>
      )}{" "}
      <GymHeader />
      <Container className="gym-card">
        {" "}
        <div className="d-flex justify-content-between align-items-center my-4">
          <div>
            <h2 className="month-year-display">
              {selectedDate.toLocaleDateString("ja-JP", {
                timeZone: "Asia/Tokyo",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </h2>
          </div>
          <h1 className="gym-header">ご利用リスト</h1> {/* gym-header スタイルを適用 */}
          <div>
            {staffAuthUser && staffAuthUser.mode === "member" && (
              <>
                <Button
                  variant="warning"
                  size="lg"
                  className="mr-2 shadow-lg"
                  onClick={handleCheckinModalShow}
                  style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                >
                  会員様 チェックイン・チェックアウト 🥊
                </Button>
              </>
            )}
            {staffAuthUser && staffAuthUser.mode === "admin" && (
              <>
                <Button
                  variant="primary"
                  onClick={handlePreviousDay}
                  style={{ marginRight: "5px" }}
                >
                  前日
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleToday}
                  className="ml-2"
                  style={{ marginRight: "5px" }}
                >
                  今日
                </Button>
                {selectedDate.toISOString().split("T")[0] <
                  new Date().toISOString().split("T")[0] && (
                  <Button variant="primary" onClick={handleNextDay} className="ml-2">
                    翌日
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <Table striped bordered hover className="gym-table">
          {" "}
          {/* gym-table スタイルを適用 */}
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">会員No.</th>
              <th scope="col">氏名</th>
              <th scope="col">入館時刻</th>
              <th scope="col">退館時刻</th>
              <th scope="col">担当スタッフ</th>
              <th scope="col">ミット完了</th>
            </tr>
          </thead>
          <tbody>
            {attendances &&
              attendances.map((attendance, index) => {
                const checkInDate = new Date(attendance.check_in);
                const formattedCheckInDate = `${checkInDate.getHours()}:${checkInDate
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`;

                const checkOutDate = attendance.check_out ? new Date(attendance.check_out) : null;
                const formattedCheckOutDate = checkOutDate
                  ? `${checkOutDate.getHours()}:${checkOutDate
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}`
                  : "";
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{attendance.user_id}</td>
                    <td onClick={() => handleShow(attendance)} style={{ cursor: "pointer" }}>
                      {attendance.user_name}
                    </td>
                    <td>{formattedCheckInDate}</td>
                    <td>{formattedCheckOutDate}</td>
                    <td>{attendance.staff_name}</td>
                    <td>
                      {attendance.staff_name && (
                        <input
                          type="checkbox"
                          checked={attendance.mitt_practice_completed}
                          onChange={(e) => handleMittPracticeCompletedChange(attendance.id, e)}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>会員様情報</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>
              {selectedUserName}（{selectedUserNameKana}）
            </h4>
            <Table className="user-info-table">
              <tbody>
                <tr>
                  <td>年齢</td>
                  <td>{selectedUserAge} 歳</td>
                </tr>
                <tr>
                  <td>ボクシング歴</td>
                  <td>{selectedUserBoxingExperience} 年</td>
                </tr>
                <tr>
                  <td>構え</td>
                  <td>
                    {selectedUserStance === "right"
                      ? "オーソドックス（右）"
                      : selectedUserStance === "left"
                      ? "サウスポー（左）"
                      : selectedUserStance === "both"
                      ? "両方"
                      : "不明"}
                  </td>
                </tr>
                <tr>
                  <td>今月の来館数</td>
                  <td>{monthlyAttendanceCount} 回目</td>
                </tr>
                <tr>
                  <td>課題</td>
                  <td>
                    <pre>{selectedUserIssues}</pre>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h5>週間出席状況</h5>
            <Table striped bordered hover className="custom-gym-table">
              <thead>
                <tr>
                  {weeklyAttendance.map((attendance, index) => (
                    <th key={index}>{formatDay(attendance.date)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {weeklyAttendance.map((attendance, index) => (
                    <td key={index}>{attendance.attended ? "◯" : "-"}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
            <Dropdown onSelect={handleStaffSelect} required>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedStaff ? selectedStaff.name : "Select Staff"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {staffs &&
                  staffs.map((staff, index) => (
                    <Dropdown.Item key={index} eventKey={staff.name}>
                      {staff.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              キャンセル
            </Button>
            <Button variant="primary" onClick={handleAssign}>
              担当する
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="checkin-modal-content"
          show={showCheckinModal}
          onHide={handleCheckinModalClose}
        >
          <Modal.Body className="checkin-modal-body">
            <form autoComplete="off">
              <div className="form-group">
                <label htmlFor="memberNumber">会員番号</label>
                <input
                  type="number"
                  retuired
                  className="form-control"
                  id="checkin_memberNumber"
                  value={memberNumber}
                  onChange={(e) => setMemberNumber(e.target.value)}
                  pattern="\d*"
                  autocomplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="checkinPassword">チェックインパスワード</label>
                <input
                  type="password"
                  retuired
                  inputmode="numeric"
                  className="form-control"
                  id="ckeckin_checkinPassword"
                  value={checkinPassword}
                  onChange={(e) => setCheckinPassword(e.target.value)}
                  autocomplete="new-password"
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="checkin-modal-footer">
            <Button variant="primary" onClick={() => handleAttendanceSubmit(memberNumber)}>
              チェックイン・チェックアウト
            </Button>
            <Button variant="secondary" onClick={handleCheckinModalClose}>
              キャンセル
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      {staffAuthUser && staffAuthUser.mode === "admin" && <GymFooter />}
    </div>
  );
}

export default GymTopPage;
