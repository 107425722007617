import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import useStaffAuthRequest from "../../hooks/useStaffAuthRequest";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import "./Gym.css";

function GymAttendances() {
  const [summary, setSummary] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // JavaScript months are 0-based
  const staffAuthRequest = useStaffAuthRequest();
  const { slug } = useParams();
  const navigate = useNavigate();

  const fetchSummary = useCallback(async () => {
    const response = await staffAuthRequest(
      `${API_URL}/api/v1/gym/attendances/monthly_summary?slug=${slug}&year=${year}&month=${month}`
    );
    setSummary(response.data);
  }, [staffAuthRequest, slug, year, month]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handlePrevMonth = () => {
    if (month > 1) {
      setMonth(month - 1);
    } else {
      setMonth(12);
      setYear(year - 1);
    }
  };

  const handleNextMonth = () => {
    if (month < 12) {
      setMonth(month + 1);
    } else {
      setMonth(1);
      setYear(year + 1);
    }
  };

  const handleCurrentMonth = () => {
    const currentDate = new Date();
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth() + 1); // JavaScript months are 0-based
  };

  const handleDateClick = (date) => {
    // YYYY-MM-DD 形式で日付をフォーマット
    const formattedDate =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");

    navigate(`/gyms/${slug}/attendances/${formattedDate}`);
  };

  const getDaysArray = (year, month) => {
    const monthStart = new Date(year, month - 1, 1);
    const monthEnd = new Date(year, month, 0);
    const daysArray = [];
    let day = monthStart;

    for (let i = 0; i < monthStart.getDay(); i++) {
      daysArray.push(null);
    }

    while (day <= monthEnd) {
      daysArray.push(new Date(day));
      day.setDate(day.getDate() + 1);
    }

    return daysArray;
  };

  const daysArray = getDaysArray(year, month);

  return (
    <div className="gym-top-page">
      {" "}
      {/* トップレベルの div を追加 */}
      <GymHeader />
      <Container className="gym-card">
        <div className="gym-header-container">
          <div>
            <h2 className="month-year-display">
              {year}年{month}月
            </h2>
          </div>
          <h1 className="gym-header my-4">入退館者数リスト</h1>
          <div>
            <Button
              onClick={handlePrevMonth}
              className="ml-2"
              style={{ marginRight: "5px" }}
            >
              ←
            </Button>
            <Button
              onClick={handleCurrentMonth}
              className="ml-2"
              style={{ marginRight: "5px" }}
            >
              今月
            </Button>
            <Button onClick={handleNextMonth}>→</Button>
          </div>
        </div>
        <Table striped bordered hover className="gym-table mt-4">
          <thead>
            <tr>
              <th>日</th>
              <th>月</th>
              <th>火</th>
              <th>水</th>
              <th>木</th>
              <th>金</th>
              <th>土</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(
              { length: Math.ceil(daysArray.length / 7) },
              (_, weekIndex) => (
                <tr key={weekIndex}>
                  {daysArray
                    .slice(weekIndex * 7, (weekIndex + 1) * 7)
                    .map((day, index) => (
                      <td key={index} onClick={() => handleDateClick(day)}>
                        {day ? (
                          <>
                            <div className="date-number">{day.getDate()}</div>
                            {/* APIデータと日付を照合し、出席情報を表示 */}
                            <div className="attendance-number">
                              {summary.find(
                                (d) =>
                                  new Date(d.date).toDateString() ===
                                  day.toDateString()
                              )?.check_ins_count.length || 0}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                    ))}
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Container>
      <GymFooter />
    </div>
  );
}

export default GymAttendances;
