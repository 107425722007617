import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { API_URL } from "../../config";
import useAuthRequest from "../../hooks/useAuthRequest";
import AdminFooter from "./AdminFooter";
import "./Admin.css";

const GymsList = () => {
  const [gyms, setGyms] = useState([]);
  const navigate = useNavigate();
  const authRequest = useAuthRequest();

  // const handleEditClick = (gymId) => {
  //   navigate(`/admin/gyms/edit/${gymId}`);
  // };

  const handleAddGym = () => {
    navigate("/admin/gyms/add");
  };

  useEffect(() => {
    const fetchGyms = async () => {
      try {
        const data = await authRequest(`${API_URL}/api/v1/admin/gyms`);
        setGyms(data);
      } catch (error) {
        console.error("ジムの取得に失敗しました:", error);
      }
    };

    fetchGyms();
  }, [authRequest]);

  return (
    <div className="admin-container">
      <div className="admin-card">
        <h2 className="admin-header">ジム一覧</h2>
        <Button variant="primary" onClick={handleAddGym} className="mb-3">
          ジム追加
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>名前</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>作成日</th>
              <th>更新日</th>
              <th>編集</th>
              <th>リンク</th>
            </tr>
          </thead>
          <tbody>
            {gyms.map((gym) => (
              <tr key={gym.id}>
                <td>{gym.id}</td>
                <td>{gym.name}</td>
                <td>{gym.email}</td>
                <td>{gym.phone}</td>
                <td>{new Date(gym.created_at).toLocaleDateString()}</td>
                <td>{new Date(gym.updated_at).toLocaleDateString()}</td>
                <td>
                  <a href={`/admin/gyms/edit/${gym.id}`}>編集</a>
                </td>
                <td>
                  <a href={`/gyms/${gym.slug}`} target="_blank" rel="noreferrer">
                    詳細
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <AdminFooter />
    </div>
  );
};

export default GymsList;
