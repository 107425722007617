import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import "./PracticeMenuPage.css";
import "./MemberFooter";
import { API_URL } from "../../config";
import useMemberAuthRequest from "../../hooks/useMemberAuthRequest";
import MemberFooter from "./MemberFooter";

const MemberInformationEdit = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [userInfo, setUserInfo] = useState({
    tel: "",
    stance: "",
    issues: "",
    boxing_experience: 0,
  });
  const [newPassword, setNewPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const [gymName, setGymName] = useState("");
  const [memberName, setMemberName] = useState("");
  const [memberId, setMemberId] = useState("");
  const memberAuthRequest = useMemberAuthRequest();

  useEffect(() => {
    memberAuthRequest(`${API_URL}/api/v1/members/user?slug=${slug}`)
      .then((response) => {
        const userData = response.data;
        setUserInfo({
          tel: userData.tel || "",
          stance: userData.stance || "",
          issues: userData.issues || "",
          boxing_experience: userData.boxing_experience || 0,
        });
        setGymName(userData.gym_name || "未設定");
        setMemberName(userData.name || "未設定");
        setMemberId(userData.member_id || "未設定");
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
      });
  }, [memberAuthRequest, slug]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedValue =
      name === "boxing_experience" ? parseInt(value, 10) : value;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    memberAuthRequest(`${API_URL}/api/v1/members/user?slug=${slug}`, {
      method: "PATCH",
      body: JSON.stringify({ user: userInfo }),
    })
      .then(() => {
        window.alert("会員情報を更新しました。"); // `window.alert`を使用してグローバルなalert関数を明示的に呼び出す
      })
      .catch((error) => {
        console.error("Error updating user info:", error);
      });
  };

  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => setShowPasswordModal(false);

  const handlePasswordUpdate = async () => {
    try {
      const passwordData = {
        user: {
          password: newPassword,
          password_confirmation: newPassword,
        },
      };
      await memberAuthRequest(`${API_URL}/api/v1/members/user?slug=${slug}`, {
        method: "PATCH",
        body: JSON.stringify(passwordData),
      });
      setAlert({
        show: true,
        message: "パスワードが更新されました。",
        variant: "success",
      });
      handleClosePasswordModal();
    } catch (error) {
      console.error("Password update failed:", error);
      setAlert({
        show: true,
        message: "パスワードの更新に失敗しました。",
        variant: "danger",
      });
    }
  };

  const handleCancel = () => {
    navigate(`/gyms/${slug}/members/dashboard`);
  };

  return (
    <div className="practice-menu-page">
      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <div className="header">
        <h2>会員情報</h2>
      </div>
      <div className="user-info-small">
        <p>ジム名: {gymName}</p>
        <p>会員ID: {memberId}</p>
        <p>会員名: {memberName}</p>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="tel">電話番号</label>
          <input
            type="text"
            name="tel"
            id="tel"
            value={userInfo.tel}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="stance">スタンス</label>
          <select
            name="stance"
            id="stance"
            value={userInfo.stance}
            onChange={handleInputChange}
          >
            <option value="right">オーソドックス（右）</option>
            <option value="left">サウスポー（左）</option>
            <option value="both">両方</option>
            <option value="unknown">不明</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="issues">課題点</label>
          <textarea
            name="issues"
            id="issues"
            value={userInfo.issues}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="boxing_experience">ボクシング経験年数</label>
          <input
            type="number"
            name="boxing_experience"
            id="boxing_experience"
            value={userInfo.boxing_experience}
            onChange={handleInputChange}
          />
        </div>
        <div className="button-group">
          <Button variant="primary" type="submit" className="me-2">
            情報更新
          </Button>
          <Button
            variant="warning"
            className="me-2"
            onClick={handleShowPasswordModal}
          >
            パスワード変更
          </Button>
          <Button variant="outline-secondary" onClick={handleCancel}>
            戻る
          </Button>
        </div>
      </form>

      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>パスワード変更</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="新しいパスワード"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            閉じる
          </Button>
          <Button variant="primary" onClick={handlePasswordUpdate}>
            更新
          </Button>
        </Modal.Footer>
      </Modal>
      <MemberFooter />
    </div>
  );
};

export default MemberInformationEdit;
