import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import { API_URL } from "../../config";
import useAuthRequest from "../../hooks/useAuthRequest";
import AdminFooter from "./AdminFooter";
import "./Admin.css";

const EditGym = () => {
  const [gym, setGym] = useState({
    name: "",
    name_kana: "",
    phone: "",
    email: "",
    postal_code: "",
    address: "",
    representative_name: "",
    status: 0,
    slug: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const authRequest = useAuthRequest();

  useEffect(() => {
    const fetchGym = async () => {
      try {
        const data = await authRequest(`${API_URL}/api/v1/admin/gyms/${id}`);
        setGym(data);
      } catch (error) {
        console.error("ジムの情報取得に失敗しました:", error);
      }
    };

    fetchGym();
  }, [id, authRequest]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authRequest(`${API_URL}/api/v1/admin/gyms/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ gym }),
      });
      navigate("/admin/gyms");
    } catch (error) {
      console.error("ジム情報の更新に失敗しました:", error);
    }
  };

  const handleCancel = () => {
    navigate("/admin/gyms");
  };

  const handlePostalCodeChange = async (e) => {
    const postalCode = e.target.value;
    setGym({ ...gym, postal_code: postalCode });
  
    if (postalCode.length === 7) {
      try {
        const response = await fetch(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
        );
        const data = await response.json();
  
        if (data.results) {
          const addressInfo = data.results[0];
          const address = `${addressInfo.address1}${addressInfo.address2}${addressInfo.address3}`;
          // 住所とともに、郵便番号も更新
          setGym({ ...gym, postal_code: postalCode, address: address });
        }
      } catch (error) {
        console.error("住所情報の取得に失敗しました:", error);
      }
    }
  };

  return (
    <div className="admin-container">
      <Card className="admin-card">
        <Card.Body>
          <h2 className="admin-header">ジム編集</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                type="text"
                required
                value={gym.name}
                onChange={(e) => setGym({ ...gym, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>名前（カナ）</Form.Label>
              <Form.Control
                type="text"
                name="name_kana"
                value={gym.name_kana}
                onChange={(e) => setGym({ ...gym, name_kana: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>slug</Form.Label>
              <Form.Control
                type="text"
                name="slug"
                value={gym.slug}
                onChange={(e) => setGym({ ...gym, slug: e.target.value })}
              />
            </Form.Group>

            {/* Phone field */}
            <Form.Group className="mb-3">
              <Form.Label>電話番号</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={gym.phone}
                onChange={(e) => setGym({ ...gym, phone: e.target.value })}
              />
            </Form.Group>

            {/* Email field */}
            <Form.Group className="mb-3">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={gym.email}
                onChange={(e) => setGym({ ...gym, email: e.target.value })}
              />
            </Form.Group>

            {/* Postal Code field */}
            <Form.Group className="mb-3">
              <Form.Label>郵便番号</Form.Label>
              <Form.Control
                type="text"
                name="postal_code"
                value={gym.postal_code}
                onChange={handlePostalCodeChange}
              />
            </Form.Group>

            {/* Address field */}
            <Form.Group className="mb-3">
              <Form.Label>住所</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={gym.address}
                onChange={(e) => setGym({ ...gym, address: e.target.value })}
              />
            </Form.Group>

            {/* Representative Name field */}
            <Form.Group className="mb-3">
              <Form.Label>代表者名</Form.Label>
              <Form.Control
                type="text"
                name="representative_name"
                value={gym.representative_name}
                onChange={(e) =>
                  setGym({ ...gym, representative_name: e.target.value })
                }
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              更新
            </Button>
            <Button variant="secondary" onClick={handleCancel} className="ms-2">
              キャンセル
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <AdminFooter />
    </div>
  );
};

export default EditGym;
