import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Container, Form } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../config";
import useStaffAuthRequest from "../../hooks/useStaffAuthRequest";
import "./Gym.css";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";

function GymStaffList() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [staffs, setStaffs] = useState([]);
  const staffAuthRequest = useStaffAuthRequest();
  const [showRetired, setShowRetired] = useState(false);

  const fetchGymStaffs = useCallback(async () => {
    try {
      const response = await staffAuthRequest(
        `${API_URL}/api/v1/gym/staffs?slug=${slug}`
      );
      setStaffs(response.data);
    } catch (error) {
      console.error("Error fetching gym staff data:", error);
      navigate("/error", { state: { status: error.status || "Error" } });
    }
  }, [navigate, slug, staffAuthRequest]);

  useEffect(() => {
    fetchGymStaffs();
  }, [fetchGymStaffs]);

  const handleWorkingStatusChange = useCallback(
    async (staff) => {
      try {
        await staffAuthRequest(
          `${API_URL}/api/v1/gym/staffs/${staff.id}?slug=${slug}`,
          {
            method: "PUT",
            body: JSON.stringify({
              staff: {
                is_working: !staff.is_working,
              },
            }),
          }
        );
        fetchGymStaffs();
      } catch (error) {
        console.error("Error updating staff working status:", error);
        alert(error.status);
      }
    },
    [fetchGymStaffs, slug, staffAuthRequest]
  );

  // 退職者の表示状態をトグルするハンドラ
  const toggleShowRetired = () => {
    setShowRetired((prev) => !prev);
  };

  // 表示するスタッフのリストを決定する
  const displayedStaffs = showRetired
    ? staffs
    : staffs.filter((staff) => !staff.is_retired);

  return (
    <div className="gym-top-page">
      {" "}
      <GymHeader />
      <Container className="gym-card">
        {" "}
        <div className="gym-header-container">
          {" "}
          <div>
            <Form.Check
              type="checkbox"
              id="retired-staff-checkbox"
              label="退職者も表示"
              checked={showRetired}
              onChange={toggleShowRetired}
            />
          </div>
          <h1 className="gym-header">スタッフ一覧</h1>
          <Link to={`/gyms/${slug}/staffs/new`}>
            <Button variant="primary">スタッフ追加</Button>{" "}
          </Link>
        </div>
        <Table striped bordered hover className="gym-table">
          {" "}
          <thead>
            <tr>
              <th>出勤中</th>
              <th>氏名（カナ）</th>
              <th>年齢</th>
              <th>電話</th>
              <th>生年月日</th>
              <th>性別</th>
              <th>在職中</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {displayedStaffs.map((staff) => (
              <tr
                key={staff.id}
                className={staff.is_retired ? "retired-staff" : ""}
              >
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={staff.is_working}
                    onChange={() => handleWorkingStatusChange(staff)}
                    disabled={staff.is_retired} // 退職済みの場合は無効化
                  />
                </td>
                <td>
                  {staff.name}（{staff.name_kana}）
                </td>
                <td>{staff.age}</td>
                <td>{staff.phone}</td>
                <td>{staff.birth}</td>
                <td>
                  {staff.gender === "male"
                    ? "男"
                    : staff.gender === "female"
                    ? "女"
                    : "未回答"}
                </td>
                <td>{staff.is_retired ? "退職済" : "在籍中"}</td>
                <td>
                  <Link to={`/gyms/${slug}/staffs/edit/${staff.id}`}>
                    <Button variant="warning" size="sm">
                      Edit
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <GymFooter />
    </div>
  );
}

export default GymStaffList;
