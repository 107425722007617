import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="/">HOME</a>
        <a href="/staffs">スタッフ管理</a>
        <a href="/users">会員様管理</a>
        <a href="/entry_exit_list">入館退館リスト</a>
        <a href="/ranking/checkins">ランキング</a>
      </div>
      <div className="footer-info">
        <p>KOD LAB</p>
        <p>〒160-0004 東京都新宿区四谷3-4 四谷SCビル2F</p>
      </div>
    </footer>
  );
}

export default Footer;
