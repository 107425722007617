import { useContext, useCallback } from 'react';
import { MemberAuthContext } from '../contexts/MemberAuthContext';

const useMemberAuthRequest = () => {
  const { memberAuthUser } = useContext(MemberAuthContext);
  const { memberAccessToken, memberClient, memberUid } = memberAuthUser || {};

  const memberAuthRequest = useCallback(async (url, options = {}) => {
    const headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'access-token': memberAccessToken,
      'client': memberClient,
      'uid': memberUid,
    };

    let response;

    try {
      response = await fetch(url, { ...options, headers });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      return data;
    } catch (error) {
      // エラーにレスポンスのステータスを追加
      error.status = response?.status;
      throw error;
    }
  }, [memberAccessToken, memberClient, memberUid]);  // memberAuthUser のプロパティを依存配列に追加

  return memberAuthRequest;
};

export default useMemberAuthRequest;
