import { useContext, useCallback } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const useAuthRequest = () => {
  const { authUser } = useContext(AuthContext);
  const { accessToken, client, uid } = authUser || {};

  const authRequest = useCallback(async (url, options = {}) => {
    const headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'access-token': accessToken,
      'client': client,
      'uid': uid,
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      throw new Error('Request failed');
    }

    return response.json();
  }, [accessToken, client, uid]);  // authUser のプロパティを依存配列に追加

  return authRequest;
};

export default useAuthRequest;
