import { useContext, useCallback } from 'react';
import { StaffAuthContext } from '../contexts/StaffAuthContext';

const useStaffAuthRequest = () => {
  const { staffAuthUser } = useContext(StaffAuthContext);
  const { staffAccessToken, staffClient, staffUid } = staffAuthUser || {};

  const staffAuthRequest = useCallback(async (url, options = {}) => {
    const headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'access-token': staffAccessToken,
      'client': staffClient,
      'uid': staffUid,
    };

    let response;

    try {
      response = await fetch(url, { ...options, headers });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      return data;
    } catch (error) {
      // エラーにレスポンスのステータスを追加
      error.status = response?.status;
      throw error;
    }
  }, [staffAccessToken, staffClient, staffUid]);  // staffAuthUser のプロパティを依存配列に追加

  return staffAuthRequest;
};

export default useStaffAuthRequest;
