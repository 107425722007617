import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Table, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_URL } from "./config";
import Footer from "./Footer/Footer"; // Import Footer

function EntryExitList() {
  const [summary, setSummary] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // JavaScript months are 0-based

  const fetchSummary = useCallback(async () => {
    const response = await axios.get(
      `${API_URL}/api/v1/attendances/monthly_summary`,
      {
        params: { year, month },
      }
    );
    setSummary(response.data.data);
  }, [year, month]); // Now fetchSummary depends on year and month

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handlePrevMonth = () => {
    if (month > 1) {
      setMonth(month - 1);
    } else {
      setMonth(12);
      setYear(year - 1);
    }
  };

  const handleNextMonth = () => {
    if (month < 12) {
      setMonth(month + 1);
    } else {
      setMonth(1);
      setYear(year + 1);
    }
  };

  const handleCurrentMonth = () => {
    const currentDate = new Date();
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth() + 1);  // JavaScript months are 0-based
  };

  return (
    <Container>
      <h1 className="my-4">Entry/Exit List</h1> {/* Header */}
      <Button onClick={handlePrevMonth}>←</Button>
      <Button onClick={handleCurrentMonth}>今月</Button>
      <Button onClick={handleNextMonth}>→</Button>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Date</th>
            <th>入館者数</th>
          </tr>
        </thead>
        <tbody>
          {summary.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>
                <Link to={`/attendance-list/${item.date}`}>
                  {item.check_ins_count}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Footer /> {/* Footer */}
    </Container>
  );
}

export default EntryExitList;
