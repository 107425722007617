import { API_URL } from '../config';

const USER_URL = `${API_URL}/api/v1/users`;

export const getUsers = async () => {
  const response = await fetch(USER_URL);
  const result = await response.json();
  return result.data;
}

export const getUser = async (id) => {
  const response = await fetch(`${USER_URL}/${id}`);
  const data = await response.json();
  return data;
}

export const addUser = async (user) => {
  const response = await fetch(USER_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  return data;
}

export const updateUser = async (id, user) => {
  const response = await fetch(`${USER_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  return data;
}

export const deleteUser = async (id) => {
  const response = await fetch(`${USER_URL}/${id}`, {
    method: "DELETE",
  });
  return response;
}
