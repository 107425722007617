import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "./config";
import { format } from "date-fns"; // Import format from date-fns for date formatting
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import { Link } from "react-router-dom";
import "./PracticeMenuPage.css"; // Import CSS styling

function PracticeMenuPage() {
  const { attendanceId } = useParams();
  const [formData, setFormData] = useState({
    pre_weight: "",
    post_weight: "",
    skipping_rope: 0,
    shadow_boxing: 0,
    sand_bag: 0,
    mitt_practice: 0,
    sparring: 0,
    check_in: "",
    roadwork: 0,
  });
  const [date, setDate] = useState(null);
  const [assignedStaff, setAssignedStaff] = useState(null);
  const [notes, setNotes] = useState(""); // stateの名前を変更

  useEffect(() => {
    axios
      .get(
        `${API_URL}/api/v1/members/attendances/${attendanceId}/practice_menu`
      )
      .then((response) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data,
        }));
        setDate(response.data.attendance_date);
        setAssignedStaff(response.data.assigned_staff); // Set the assigned staff
        setNotes(response.data.notes); // レスポンスからメモをセット
      })
      .catch((error) => {
        console.error(
          "There was an error retrieving the practice menu:",
          error
        );
      });
  }, [attendanceId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const dataToSend = {
      practice_menu: {
        ...formData,
        notes, // formDataにnotesを追加
      },
    };
    axios
      .patch(
        `${API_URL}/api/v1/members/practice_menus/${attendanceId}`,
        dataToSend
      )
      .then(() => {
        alert("練習メニューを保存しました。");
      })
      .catch((error) => {
        console.error("There was an error updating the practice menu:", error);
      });
  };

  return (
    <div className="practice-menu-page">
      <div className="dashboard-link-section">
        <Link to="/members/dashboard" className="header-link">
          ダッシュボードへ戻る
        </Link>
      </div>
      <div className="header">
        <h2>
          {date ? format(new Date(date), "yyyy/MM/dd") : "Loading..."}の練習
        </h2>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="pre_weight">練習前体重</label>
          <div className="input-with-unit">
            <input
              type="number"
              name="pre_weight"
              id="pre_weight"
              value={formData.pre_weight}
              onChange={handleInputChange}
            />{" "}
            <span className="unit-label">Kg</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="post_weight">練習後体重</label>
          <div className="input-with-unit">
            <input
              type="number"
              name="post_weight"
              id="post_weight"
              value={formData.post_weight}
              onChange={handleInputChange}
            />{" "}
            <span className="unit-label">Kg</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="skipping_rope">縄跳び</label>
          <div className="input-with-unit">
            <select
              name="skipping_rope"
              id="skipping_rope"
              value={formData.skipping_rope}
              onChange={handleInputChange}
            >
              {[...Array(11).keys()].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>{" "}
            <span className="unit-label">R</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="shadow_boxing">シャドウボクシング</label>
          <div className="input-with-unit">
            <select
              name="shadow_boxing"
              id="shadow_boxing"
              value={formData.shadow_boxing}
              onChange={handleInputChange}
            >
              {[...Array(11).keys()].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>{" "}
            <span className="unit-label">R</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="sand_bag">サンドバッグ</label>
          <div className="input-with-unit">
            <select
              name="sand_bag"
              id="sand_bag"
              value={formData.sand_bag}
              onChange={handleInputChange}
            >
              {[...Array(11).keys()].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>{" "}
            <span className="unit-label">R</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="mitt_practice">
            ミット{assignedStaff ? `（担当 : ${assignedStaff}）` : ""}
          </label>
          <div className="input-with-unit">
            <select
              name="mitt_practice"
              id="mitt_practice"
              value={formData.mitt_practice}
              onChange={handleInputChange}
            >
              {[...Array(11).keys()].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>{" "}
            <span className="unit-label">R</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="sparring">スパーリング</label>
          <div className="input-with-unit">
            <select
              name="sparring"
              id="sparring"
              value={formData.sparring}
              onChange={handleInputChange}
            >
              {[...Array(11).keys()].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>{" "}
            <span className="unit-label">R</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="roadwork">ロードワーク</label>
          <div className="input-with-unit">
            <input
              type="number"
              name="roadwork"
              id="roadwork"
              value={formData.roadwork}
              onChange={handleInputChange}
              min="0" // 負の値を防ぐために最小値を設定
            />{" "}
            <span className="unit-label">Km</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="notes">メモ</label>
          <textarea
            name="notes"
            id="notes"
            value={notes}
            onChange={handleNotesChange}
          />
        </div>
        <button type="submit" className="save-button">
          保存
        </button>
      </form>
    </div>
  );
}

export default PracticeMenuPage;
