import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUser, updateUser } from "../services/userService";
import { Form, Button, Container } from "react-bootstrap";

function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    tel: "",
    stance: "",
    birthday: "",
    issues: "",
    boxing_experience: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUser(id);
      // APIの応答に基づいて適切にデータをセットします。
      setUserData(result.data || {});
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateUser(id, userData);
    navigate("/users");
  };

  const handleCancel = () => {
    navigate("/users");
  };

  return (
    <Container>
      <h2 className="my-4">Edit User</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={userData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formTel">
          <Form.Label>Telephone</Form.Label>
          <Form.Control
            type="tel"
            name="tel"
            value={userData.tel}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formHandedness">
          <Form.Label>構え</Form.Label>
          <Form.Control
            as="select"
            name="stance"
            value={userData.stance}
            onChange={handleChange}
          >
            <option value="right">オーソドックス（右）</option>
            <option value="left">サウスポー（左）</option>
            <option value="both">両方</option>
            <option value="unknown">不明</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formBirthday">
          <Form.Label>Birthday</Form.Label>
          <Form.Control
            type="date"
            name="birthday"
            value={userData.birthday}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formIssues">
          <Form.Label>Issues/Concerns</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="issues"
            value={userData.issues}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formBoxingExperience">
          <Form.Label>Boxing Experience (Years)</Form.Label>
          <Form.Control
            type="number"
            name="boxing_experience" // 修正
            value={userData.boxing_experience} // 修正
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="me-2">
          Update
        </Button>
        <Button variant="outline-secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Form>
    </Container>
  );
}

export default EditUser;
