import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import { API_URL } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import "./Admin.css";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth(); // useAuth フックから login 関数を取得

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch(`${API_URL}/admin_auth/sign_in`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("ログインに失敗しました。");
      }

      const accessToken = response.headers.get("access-token");
      const client = response.headers.get("client");
      const uid = response.headers.get("uid");

      login({ accessToken, client, uid });

      navigate("/admin/dashboard");
    } catch (error) {
      setError(
        "ログインに失敗しました。メールアドレスまたはパスワードが正しくありません。"
      );
    }
  };

  return (
    <div className="admin-container">
      <Card className="admin-card">
        <Card.Body>
          <h2 className="admin-header text-center">管理者ログイン</h2>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form onSubmit={handleLogin}>
            <Form.Group id="email">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button className="admin-button w-100 mt-3" type="submit">
              ログイン
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminLogin;
