import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMemberAuth } from "../../contexts/MemberAuthContext";
import "./Member.css";

const MemberFooter = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { logout } = useMemberAuth();

  const handleLogout = async () => {
    await logout();
    navigate(`/gyms/${slug}/members/login`);
  };

  const navigateToMemberInfo = () => {
    navigate(`/gyms/${slug}/members/information`);
  };

  const navigateToMemberTop = () => {
    navigate(`/gyms/${slug}/members/dashboard`);
  };

  return (
    <div className="member-footer">
      <button onClick={navigateToMemberTop} className="member-info-button">
        ダッシュボード
      </button>
      <button onClick={navigateToMemberInfo} className="member-info-button" style={{ marginRight: "10px" }}>
        会員情報
      </button>
      <button onClick={handleLogout} className="member-logout-button">
        ログアウト
      </button>
    </div>
  );
};

export default MemberFooter;
