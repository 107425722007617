import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Table, Container, Button } from "react-bootstrap";
import { useGymUserService } from "../../services/gymUserService";
import "./Gym.css";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";

function GymUserList() {
  const [users, setUsers] = useState([]);
  const { slug } = useParams();
  const { getGymUsers } = useGymUserService(slug);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getGymUsers();
      setUsers(data);
    };

    fetchData();
  }, [getGymUsers]);

  return (
    <div className="gym-top-page">
      {" "}
      {/* GymStaffList の最外層のスタイルを適用 */}
      <GymHeader />
      <Container className="gym-card">
        {" "}
        {/* GymStaffList のコンテナスタイルを適用 */}
        <div className="gym-header-container">
          {" "}
          {/* 新しいdiv要素 */}
          <div></div>
          <h1 className="gym-header">会員様一覧</h1>
          <Link to={`/gyms/${slug}/users/new`}>
            <Button variant="primary">会員追加</Button>
          </Link>
        </div>
        <Table striped bordered hover className="gym-table">
          {" "}
          {/* GymStaffList のテーブルスタイルを適用 */}
          <thead>
            <tr>
              <th></th>
              <th>会員ID</th>
              <th>氏名</th>
              <th>電話</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td> {/* 連番 */}
                <td>{user.member_id}</td>
                <td>{user.name}</td>
                <td>{user.tel}</td>
                <td>
                  <Link to={`/gyms/${slug}/users/edit/${user.id}`}>
                    <Button variant="warning" size="sm">
                      Edit
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <GymFooter /> {/* 共通のフッターコンポーネント */}
    </div>
  );
}

export default GymUserList;
