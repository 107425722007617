import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';
import useStaffAuthRequest from '../../hooks/useStaffAuthRequest';
import { API_URL } from '../../config';
import GymHeader from './GymHeader';
import GymFooter from './GymFooter';

const GymAttendanceRanking = () => {
  const [ranking, setRanking] = useState([]);
  const staffAuthRequest = useStaffAuthRequest();
  const { slug } = useParams();

  // ブラウザのタイムゾーンに基づいて現在の年と月を取得
  const currentTimeZoneDate = new Date().toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const currentYear = new Date(currentTimeZoneDate).getFullYear();
  const currentMonth = new Date(currentTimeZoneDate).getMonth() + 1;

  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);

  const fetchRanking = useCallback(async () => {
    try {
      const response = await staffAuthRequest(
        `${API_URL}/api/v1/gym/attendances/monthly_attendance_ranking?slug=${slug}&year=${year}&month=${month}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
      );
      setRanking(response.data);
    } catch (error) {
      console.error('Error fetching attendance ranking:', error);
    }
  }, [staffAuthRequest, slug, year, month]);

  // 月移動のハンドラー
  const handleMonthChange = (change) => {
    let newDate = new Date(year, month - 1 + change);
    setYear(newDate.getFullYear());
    setMonth(newDate.getMonth() + 1);
  };

  useEffect(() => {
    fetchRanking();
  }, [fetchRanking]);

  // 現在の月が今月かどうかを確認
  const isCurrentMonth = year === currentYear && month === currentMonth;
  
  return (
    <div className="gym-top-page">
      <GymHeader />
      <Container className="gym-card">
        <h1 className="gym-header">{year}年{month}月の来館者ランキング</h1>
        <div className="month-navigation-buttons">
          <Button variant="outline-primary" onClick={() => handleMonthChange(-1)}>
            前月
          </Button>
          <Button variant="outline-primary" onClick={() => {
            setYear(new Date().getFullYear());
            setMonth(new Date().getMonth() + 1);
          }}>
            今月
          </Button>
          {isCurrentMonth ? null : (
            <Button variant="outline-primary" onClick={() => handleMonthChange(1)}>
              次月
            </Button>
          )}
        </div>
        <Table striped bordered hover className="gym-table">
          <thead>
            <tr>
              <th>順位</th>
              <th>ユーザー名</th>
              <th>来館回数</th>
            </tr>
          </thead>
          <tbody>
            {ranking.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.user_name}</td>
                <td>{item.count}回</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <GymFooter />
    </div>
  );
};

export default GymAttendanceRanking;
