import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useStaffAuthRequest from '../../hooks/useStaffAuthRequest';
import { API_URL } from '../../config';
import { useStaffAuth } from '../../contexts/StaffAuthContext';

function GymHeader() {
  const [gymName, setGymName] = useState('');
  const navigate = useNavigate();
  const { slug } = useParams();
  const staffAuthRequest = useStaffAuthRequest();
  const { logout } = useStaffAuth();

  const handleDoubleClick = () => {
    const confirmLogout = window.confirm('ログアウトしますか？');
    if (confirmLogout) {
      logout();
    }
  };
  
  useEffect(() => {
    const fetchGymData = async () => {
      try {
        const data = await staffAuthRequest(`${API_URL}/api/v1/gym/top_page/${slug}`);
        setGymName(data.name);
      } catch (error) {
        console.error('Error fetching gym data:', error);
        navigate('/error', { state: { status: error.status || 'Error' } });
      }
    };

    fetchGymData();
  }, [navigate, slug, staffAuthRequest]);

  return (
    <div className="gym-name-header">
      <h2 onDoubleClick={handleDoubleClick}>{gymName}</h2>
    </div>
  );
}

export default GymHeader;
