import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../config";
import { useStaffAuth } from "../../contexts/StaffAuthContext";
import "./GymInformation.css";
import LoadingDots from "../common/LoadingDots";

function GymInformation() {
  const { slug } = useParams();
  const [gymInfo, setGymInfo] = useState({ name: "", name_kana: "", phone: "" });
  const [isLoading, setIsLoading] = useState(true);
  const { staffAuthUser } = useStaffAuth();
  const navigate = useNavigate();
  const renderLinkWithIcon = (url, iconClass) => {
    return url ? (
      <td>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <i className={iconClass}></i> {url}
        </a>
      </td>
    ) : (
      <td>-</td>
    );
  };

  const renderGoogleMapLink = (postalCode, address) => {
    const fullAddress = `〒${postalCode} ${address}`;
    const query = encodeURIComponent(fullAddress);
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;
    return (
      <td>
        <a href={googleMapUrl} target="_blank" rel="noopener noreferrer">
          {fullAddress} <i className="fas fa-map-marker-alt"></i>
        </a>
      </td>
    );
  };

  useEffect(() => {
    const fetchGymInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/gyms?slug=${slug}`);
        if (response.data) {
          setGymInfo(response.data);
        } else {
          // ジムの情報が存在しない場合、エラーページにリダイレクト
          navigate("/error", { state: { status: 404 } });
        }
      } catch (error) {
        console.error("ジムの情報取得エラー", error);
        // エラーページにリダイレクト
        navigate("/error", { state: { status: error.response?.status || "Error" } });
      } finally {
        setIsLoading(false);
      }
    };

    fetchGymInfo();
  }, [navigate, slug, staffAuthUser]);

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="gym-info-page-style">
      <h1 className="gym-name">{gymInfo.name}</h1>
      <table className="gym-info-table">
        <tbody>
          <tr>
            <th>ジム名称</th>
            <td>
              {gymInfo.name}（{gymInfo.name_kana}）
            </td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{gymInfo.phone}</td>
          </tr>
          <tr>
            <th>住所</th>
            {renderGoogleMapLink(gymInfo.postal_code, gymInfo.address)}
          </tr>
          <tr>
            <th>代表者名</th>
            <td>{gymInfo.representative_name}</td>
          </tr>
          <tr>
            <th>ホームページ</th>
            {renderLinkWithIcon(gymInfo.homepage_url, "fas fa-external-link-alt")}
          </tr>
          <tr>
            <th>YouTube</th>
            {renderLinkWithIcon(gymInfo.youyube_url, "fab fa-youtube")}
          </tr>
          <tr>
            <th>Instagram</th>
            {renderLinkWithIcon(gymInfo.instagram_url, "fab fa-instagram")}
          </tr>
          <tr>
            <th>Twitter</th>
            {renderLinkWithIcon(gymInfo.twitter_url, "fab fa-twitter")}
          </tr>
        </tbody>
      </table>
      <div className="gym-info-links">
        <Link className="gym-info-link" to={`/gyms/${slug}/login`}>
          スタッフログイン
        </Link>
        <Link className="gym-info-link" to={`/gyms/${slug}/members/login`}>
          会員ログイン
        </Link>
      </div>
    </div>
  );
}

export default GymInformation;
