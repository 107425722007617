import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Container, Modal, Alert } from "react-bootstrap";
import { useGymUserService } from "../../services/gymUserService";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import "./Gym.css";

function GymUserEdit() {
  const { id } = useParams();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { getGymUser, updateGymUser } = useGymUserService(slug);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });

  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => setShowPasswordModal(false);

  // パスワード更新のハンドラ
  const handlePasswordUpdate = async () => {
    try {
      const passwordData = {
        user: {
          password: newPassword,
          password_confirmation: newPassword,
        }
      };
      await updateGymUser(id, { ...userData, ...passwordData });
      setAlert({
        show: true,
        message: "パスワードが更新されました。",
        variant: "success",
      });
      handleClosePasswordModal();
    } catch (error) {
      console.error("Password update failed:", error);
      setAlert({
        show: true,
        message: "パスワードの更新に失敗しました。",
        variant: "danger",
      });
    }
  };

  const [userData, setUserData] = useState({
    name: "",
    name_kana: "",
    tel: "",
    stance: "",
    birthday: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGymUser(id);
      setUserData(result.data || {});
    };

    fetchData();
  }, [getGymUser, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateGymUser(id, userData);
    navigate(`/gyms/${slug}/users`);
  };

  const handleCancel = () => {
    navigate(`/gyms/${slug}/users`);
  };

  return (
    <div className="gym-top-page">
      <GymHeader />
      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Container className="edit-user-container">
        <h2 className="my-4 gym-header sticky-header">会員情報編集</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="form-group-spacing">
            <Form.Label>氏名</Form.Label>
            <Form.Control
              type="text"
              required
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formNameKana" className="form-group-spacing">
            <Form.Label>氏名カナ</Form.Label>
            <Form.Control
              type="text"
              required
              name="name_kana"
              value={userData.name_kana}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formTel" className="form-group-spacing">
            <Form.Label>Telephone</Form.Label>
            <Form.Control
              type="tel"
              required
              name="tel"
              value={userData.tel}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formHandedness" className="form-group-spacing">
            <Form.Label>構え</Form.Label>
            <Form.Control
              as="select"
              name="stance"
              value={userData.stance}
              onChange={handleChange}
            >
              <option value="right">オーソドックス（右）</option>
              <option value="left">サウスポー（左）</option>
              <option value="both">両方</option>
              <option value="unknown">不明</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formBirthday" className="form-group-spacing">
            <Form.Label>Birthday</Form.Label>
            <Form.Control
              type="date"
              required
              name="birthday"
              value={userData.birthday}
              onChange={handleChange}
            />
          </Form.Group>

          <div className="button-group">
            <Button variant="primary" type="submit" className="me-2">
              情報更新
            </Button>
            <Button
              variant="warning"
              className="me-2"
              onClick={handleShowPasswordModal}
            >
              パスワード変更
            </Button>
            <Button variant="outline-secondary" onClick={handleCancel}>
              戻る
            </Button>
          </div>
        </Form>
      </Container>
      {/* パスワード変更モーダル */}
      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>パスワード変更</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="新しいパスワード"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            閉じる
          </Button>
          <Button variant="primary" onClick={handlePasswordUpdate}>
            更新
          </Button>
        </Modal.Footer>
      </Modal>

      <GymFooter />
    </div>
  );
}

export default GymUserEdit;
