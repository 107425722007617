import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { API_URL } from "./config";
import Footer from "./Footer/Footer";
import { useParams } from "react-router-dom";

function AttendanceList() {
  const [attendances, setAttendances] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedAttendanceId, setSelectedAttendanceId] = useState(null);
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const { date } = useParams();
  const nowInTokyo = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
  );
  const initialDate = date ? new Date(date) : nowInTokyo;
  const [selectedDate, setSelectedDate] = useState(initialDate);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [memberNumber, setMemberNumber] = useState("");
  const [checkinPassword, setCheckinPassword] = useState("");
  const [selectedUserAge, setSelectedUserAge] = useState("-");
  const [selectedUserIssues, setSelectedUserIssues] = useState("");
  const [selectedUserStance, setSelectedUserStance] = useState("");
  const [selectedUserBoxingExperience, setSelectedUserBoxingExperience] =
    useState(""); // 追加

  const handleCheckinModalClose = () => setShowCheckinModal(false);
  const handleCheckinModalShow = () => {
    setMemberNumber("");
    setCheckinPassword("");
    setShowCheckinModal(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = (attendance) => {
    setSelectedUserName(attendance.user_name);
    setSelectedUserAge(attendance.user_age);
    setSelectedUserIssues(attendance.user_issues);
    setSelectedUserStance(attendance.user_stance);
    setSelectedUserBoxingExperience(attendance.user_boxing_experience);
    setSelectedAttendanceId(attendance.id);
    setShow(true);
  };

  const handleStaffSelect = (staff) => {
    const selected = staffs.find((s) => s.name === staff);
    setSelectedStaff(selected);
  };

  const handleAssign = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        assignment: {
          attendance_id: selectedAttendanceId,
          staff_id: selectedStaff.id,
        },
      }),
    };
    fetch(`${API_URL}/api/v1/assignments`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setRefresh(!refresh);
      });

    setShow(false);
  };

  const handlePreviousDay = () => {
    let prevDay = new Date(selectedDate);
    prevDay.setDate(prevDay.getDate() - 1);
    setSelectedDate(prevDay);
    setRefresh(!refresh);
  };

  const handleNextDay = () => {
    let nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setSelectedDate(nextDate);
  };

  const handleToday = () => {
    let todayDate = new Date();
    setSelectedDate(todayDate);
    setRefresh(!refresh);
  };

  const handleAttendanceSubmit = (attendanceUserId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        memberNumber,
        checkinPassword,
      }),
    };
    const endpoint = attendanceUserId
      ? `${API_URL}/api/v1/users/${attendanceUserId}/attendances`
      : `${API_URL}/api/v1/users/${memberNumber}/attendances`;

    fetch(endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          alert(data.message);
          setRefresh(!refresh); // リストをリフレッシュ
          handleCheckinModalClose(); // モーダルを閉じる
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        alert("操作中にエラーが発生しました: " + error.message);
      });
  };

  const handleMittPracticeCompletedChange = (attendanceId) => {
    // API エンドポイントに対してフェッチリクエストを行い、mitt_practice_completed の状態を更新する
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        mitt_practice_completed: !attendances.find((a) => a.id === attendanceId)
          .mitt_practice_completed,
      }),
    };
    fetch(`${API_URL}/api/v1/attendances/${attendanceId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // レスポンスを確認し、状態を更新するか、エラーメッセージを表示する
        if (data.status === "SUCCESS") {
          setRefresh(!refresh); // リストをリフレッシュ
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        alert("操作中にエラーが発生しました: " + error.message);
      });
  };

  useEffect(() => {
    const formattedDate = new Intl.DateTimeFormat("en-CA", {
      timeZone: "Asia/Tokyo",
    }).format(selectedDate);
    
    console.log('formattedDate : ', formattedDate);

    fetch(`${API_URL}/api/v1/attendances?date=${formattedDate}`)
      .then((response) => response.json())
      .then((data) => setAttendances(data.data));

    fetch(`${API_URL}/api/v1/staffs/working`)
      .then((response) => response.json())
      .then((data) => setStaffs(data.data));
  }, [refresh, selectedDate]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center my-4">
        <h1>ご利用リスト - {selectedDate.toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' })}</h1>
        <div>
          <Button
            variant="warning" // 明るい黄色の背景
            size="lg" // 大きなボタンサイズ
            className="mr-2 shadow-lg" // 影を追加
            onClick={handleCheckinModalShow}
            style={{ fontSize: "1.2rem" }} // フォントサイズを大きく
          >
            会員様 チェックイン・チェックアウト
          </Button>
          <Button variant="primary" onClick={handlePreviousDay}>
            Previous Day
          </Button>
          <Button variant="secondary" onClick={handleToday} className="ml-2">
            Today
          </Button>
          {selectedDate.toISOString().split("T")[0] <
            new Date().toISOString().split("T")[0] && (
            <Button variant="primary" onClick={handleNextDay} className="ml-2">
              Next Day
            </Button>
          )}
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">会員No.</th>
            <th scope="col">氏名</th>
            <th scope="col">入館時刻</th>
            <th scope="col">退館時刻</th>
            <th scope="col">担当スタッフ</th>
            <th scope="col">ミット done!</th>
          </tr>
        </thead>
        <tbody>
          {attendances &&
            attendances.map((attendance, index) => {
              const checkInDate = new Date(attendance.check_in);
              const formattedCheckInDate = `${checkInDate.getHours()}:${checkInDate.getMinutes().toString().padStart(2, '0')}`;

              const checkOutDate = attendance.check_out ? new Date(attendance.check_out) : null;
              const formattedCheckOutDate = checkOutDate ? `${checkOutDate.getHours()}:${checkOutDate.getMinutes().toString().padStart(2, '0')}` : "";
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{attendance.user_id}</td>
                  <td
                    onClick={() => handleShow(attendance)}
                    style={{ cursor: "pointer" }}
                  >
                    {attendance.user_name}
                  </td>
                  <td>{formattedCheckInDate}</td>
                  <td>{formattedCheckOutDate}</td>
                  <td>{attendance.staff_name}</td>
                  <td>
                    {attendance.staff_name && (
                      <input
                        type="checkbox"
                        checked={attendance.mitt_practice_completed}
                        onChange={() =>
                          handleMittPracticeCompletedChange(attendance.id)
                        }
                      />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>会員様情報</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUserName}({selectedUserAge})
          <p>ボクシング歴: {selectedUserBoxingExperience}</p>
          <p>構え: {selectedUserStance}</p>
          <p>課題: {selectedUserIssues}</p>
          <Dropdown onSelect={handleStaffSelect}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {selectedStaff ? selectedStaff.name : "Select Staff"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {staffs &&
                staffs.map((staff, index) => (
                  <Dropdown.Item key={index} eventKey={staff.name}>
                    {staff.name}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAssign}>
            Assign
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCheckinModal} onHide={handleCheckinModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Check in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="memberNumber">会員番号</label>
              <input
                type="number"
                className="form-control"
                id="memberNumber"
                value={memberNumber}
                onChange={(e) => setMemberNumber(e.target.value)}
                pattern="\d*"
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkinPassword">チェックインパスワード</label>
              <input
                type="password"
                inputmode="numeric"
                className="form-control"
                id="checkinPassword"
                value={checkinPassword}
                onChange={(e) => setCheckinPassword(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCheckinModalClose}>
            キャンセル
          </Button>
          <Button
            variant="primary"
            onClick={() => handleAttendanceSubmit(memberNumber)}
          >
            チェックイン・チェックアウト
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
}

export default AttendanceList;
