import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Container } from "react-bootstrap";
import { API_URL } from '../config';
import Footer from "../Footer/Footer";

function MittPracticeStatus() {
  const [staffs, setStaffs] = useState([]);

  const fetchMittPracticeStatus = async () => {
    const response = await axios.get(`${API_URL}/api/v1/staffs/mitt_practice_list`);
    setStaffs(response.data.data);
  };

  useEffect(() => {
    fetchMittPracticeStatus();
  }, []);

  return (
    <Container>
      <h1 className="my-4">Mitt Practice Status</h1>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>ミットを持った回数</th>
          </tr>
        </thead>
        <tbody>
          {staffs.map((staff) => (
            <tr key={staff.id}>
              <td>{staff.name}</td>
              <td>{staff.mitt_practice_count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Footer />
    </Container>
  );
}

export default MittPracticeStatus;
