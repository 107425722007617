import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useMemberAuth } from '../../contexts/MemberAuthContext';
import LoadingIndicator from '../../LoadingIndicator';

const GymMemberRoute = ({ children }) => {
  const { memberAuthUser, loading } = useMemberAuth();
  const location = useLocation();
  const { slug } = useParams();

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!memberAuthUser) {
    return <Navigate to={`/gyms/${slug}/members/login`} state={{ from: location }} replace />;
  }

  return children;
};

export default GymMemberRoute;
