import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Container, Alert, Modal } from "react-bootstrap";
import { useGymStaffService } from "../../services/gymStaffService";
import GymFooter from "./GymFooter";
import GymHeader from "./GymHeader";
import "./Gym.css";

function GymStaffEdit() {
  const { id } = useParams();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { getGymStaff, updateGymStaff } = useGymStaffService(slug);
  // const staffAuthRequest = useStaffAuthRequest(); // カスタムフックを使用
  const [name, setName] = useState(null);
  const [nameEn, setNameEn] = useState(null);
  const [nameKana, setNameKana] = useState(null);
  const [birth, setBirth] = useState(null);
  const [gender, setGender] = useState(null);
  const [career, setCareer] = useState(null);
  const [hobby, setHobby] = useState(null);
  // const [isWorking, setIsWorking] = useState(null);
  const [isRetired, setIsRetired] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  // const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });

  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => setShowPasswordModal(false);

  useEffect(() => {
    const fetchStaffData = async () => {
      const result = await getGymStaff(id);
      const staff = result.data;
      setName(staff.name);
      setNameEn(staff.name_en);
      setNameKana(staff.name_kana);
      setBirth(staff.birth);
      setGender(staff.gender);
      setCareer(staff.career);
      setHobby(staff.hobby);
      // setIsWorking(staff.is_working);
      setIsRetired(staff.is_retired);
      setEmail(staff.email);
      setPhone(staff.phone);
    };

    fetchStaffData();
  }, [getGymStaff, id, slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const staff = {
      name,
      name_en: nameEn,
      name_kana: nameKana,
      birth,
      gender,
      career,
      hobby,
      // is_working: isWorking,
      is_retired: isRetired,
      email,
      phone,
    };

    await updateGymStaff(id, staff);
    navigate(`/gyms/${slug}/staffs`);
  };

  const handleCancel = () => {
    navigate(`/gyms/${slug}/staffs`);
  };

  // パスワード更新のハンドラ
  const handlePasswordUpdate = async () => {
    try {
      const passwordData = {
        staff: {
          password: newPassword,
          password_confirmation: newPassword,
        },
      };
      await updateGymStaff(id, passwordData);
      setAlert({
        show: true,
        message: "パスワードが更新されました。",
        variant: "success",
      });
      handleClosePasswordModal();
    } catch (error) {
      console.error("Password update failed:", error);
      setAlert({
        show: true,
        message: "パスワードの更新に失敗しました。",
        variant: "danger",
      });
    }
  };

  if (
    name === null ||
    birth === null ||
    gender === null ||
    // isWorking === null ||
    isRetired === null
  ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="gym-top-page">
      <GymHeader />
      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Container className="edit-user-container">
        <h2 className="my-4 gym-header sticky-header">スタッフ情報編集</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="form-group-spacing">
            <Form.Label>氏名</Form.Label>
            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formNameKana" className="form-group-spacing">
            <Form.Label>氏名カナ</Form.Label>
            <Form.Control
              type="text"
              value={nameKana}
              onChange={(e) => setNameKana(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="form-group-spacing">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formPhone" className="form-group-spacing">
            <Form.Label>電話</Form.Label>
            <Form.Control type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formBirth" className="form-group-spacing">
            <Form.Label>生年月日</Form.Label>
            <Form.Control type="date" value={birth} onChange={(e) => setBirth(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formGender" className="form-group-spacing">
            <Form.Label>性別</Form.Label>
            <Form.Select
              aria-label="Gender select"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select...</option>
              <option value="male">男</option>
              <option value="female">女</option>
              <option value="other">未回答</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="formCareer" className="form-group-spacing">
            <Form.Label>経歴</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={career}
              onChange={(e) => setCareer(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formHobby" className="form-group-spacing">
            <Form.Label>趣味</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={hobby}
              onChange={(e) => setHobby(e.target.value)}
            />
          </Form.Group>

          {/* <Form.Group controlId="formIsWorking" className="form-group-spacing">
            <Form.Check
              type="checkbox"
              label="Is Working"
              checked={isWorking}
              onChange={(e) => setIsWorking(e.target.checked)}
            />
          </Form.Group> */}

          <Form.Group controlId="formIsRetired" className="form-group-spacing">
            <Form.Check
              type="checkbox"
              label="退職済み"
              checked={isRetired}
              onChange={(e) => setIsRetired(e.target.checked)}
            />
          </Form.Group>

          <div className="button-group">
            <Button variant="primary" type="submit" className="me-2">
              情報更新
            </Button>
            <Button variant="warning" className="me-2" onClick={handleShowPasswordModal}>
              パスワード変更
            </Button>
            <Button variant="outline-secondary" onClick={handleCancel}>
              戻る
            </Button>
          </div>
        </Form>
      </Container>
      {/* パスワード変更モーダル */}
      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>パスワード変更</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="新しいパスワード"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            閉じる
          </Button>
          <Button variant="primary" onClick={handlePasswordUpdate}>
            更新
          </Button>
        </Modal.Footer>
      </Modal>
      <GymFooter />
    </div>
  );
}

export default GymStaffEdit;
