import React, { createContext, useContext, useState, useEffect } from "react";

export const StaffAuthContext = createContext(null);

export function useStaffAuth() {
  return useContext(StaffAuthContext);
}

export const StaffAuthProvider = ({ children }) => {
  const [staffAuthUser, setStaffAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('');

  useEffect(() => {
    const staffAccessToken = localStorage.getItem("staffAccessToken");
    const staffClient = localStorage.getItem("staffClient");
    const staffUid = localStorage.getItem("staffUid");
    const savedMode = localStorage.getItem("staffMode"); // mode をローカルストレージから読み込む

    if (staffAccessToken && staffClient && staffUid) {
      setStaffAuthUser({ staffAccessToken, staffClient, staffUid, mode: savedMode });
      setMode(savedMode); // mode を設定する
    }
    setLoading(false);
  }, []);

  const login = ({ staffAccessToken, staffClient, staffUid, mode }) => {
    localStorage.setItem("staffAccessToken", staffAccessToken);
    localStorage.setItem("staffClient", staffClient);
    localStorage.setItem("staffUid", staffUid);
    localStorage.setItem("staffMode", mode); // mode をローカルストレージに保存する
  
    const newUser = { staffAccessToken, staffClient, staffUid, mode };
    setStaffAuthUser(newUser);
    setMode(mode);
  };

  const logout = () => {
    localStorage.removeItem("staffAccessToken");
    localStorage.removeItem("staffClient");
    localStorage.removeItem("staffUid");
    localStorage.removeItem("staffMode"); // mode をローカルストレージから削除する
    setStaffAuthUser(null);
    setMode(null);
  };

  const value = {
    staffAuthUser,
    loading,
    mode,
    login,
    logout,
  };

  return <StaffAuthContext.Provider value={value}>{children}</StaffAuthContext.Provider>;
};
