import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useStaffAuth } from '../../contexts/StaffAuthContext';
import LoadingIndicator from '../../LoadingIndicator';

const GymStaffRoute = ({ children }) => {
  const { staffAuthUser, loading } = useStaffAuth();
  const location = useLocation();
  const { slug } = useParams(); // URLからslugを取得

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!staffAuthUser) {
    // スタッフが認証されていない場合、ジム固有のログインページへリダイレクト
    return <Navigate to={`/gyms/${slug}`} state={{ from: location }} replace />;
  }

  return children;
};

export default GymStaffRoute;
