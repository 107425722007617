import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import { API_URL } from "../../config";
import useAuthRequest from "../../hooks/useAuthRequest";
import AdminFooter from "./AdminFooter";
import "./Admin.css";

const AddAdminUser = () => {
  const [newUser, setNewUser] = useState({ name: "", email: "", password: "" });
  const navigate = useNavigate();
  const authRequest = useAuthRequest();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authRequest(`${API_URL}/api/v1/admin/admin_users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          admin_user: {
            ...newUser,
            password_confirmation: newUser.password,
          },
        }),
      });
      navigate("/admin/admin_users");
    } catch (error) {
      console.error("管理ユーザーの追加に失敗しました:", error);
    }
  };

  const handleCancel = () => {
    navigate("/admin/admin_users");
  };

  useEffect(() => {
    setNewUser({ name: "", email: "", password: "" });
  }, []);

  return (
    <div className="admin-container">
      <Card className="admin-card">
        <Card.Body>
          <h2 className="admin-header">管理ユーザー追加</h2>
          <Form onSubmit={handleSubmit}>
            {/* 名前 */}
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                type="text"
                required
                value={newUser.name}
                onChange={(e) =>
                  setNewUser({ ...newUser, name: e.target.value })
                }
              />
            </Form.Group>
            {/* メールアドレス */}
            <Form.Group className="mb-3">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                required
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
            </Form.Group>
            {/* パスワード */}
            <Form.Group className="mb-3">
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                type="password"
                required
                value={newUser.password}
                onChange={(e) =>
                  setNewUser({ ...newUser, password: e.target.value })
                }
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              追加
            </Button>
            <Button variant="secondary" type="button" onClick={handleCancel}>
              キャンセル
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <AdminFooter />
    </div>
  );
};

export default AddAdminUser;
